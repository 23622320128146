import Axios from '../../configurations/axios';
import {
  FETCH_PAN_URL,
  BORROWERS_URL,
  BORROWER_AUTHENTICATION_URL,
  ACTIVATION_LIST_URL,
  BORROWER_DETAIL_URL,
  PIN_CODE_URL,
  GET_COMPANY_ANCHOR,
  GET_ANCHOR_KEY_URL,
  DECRYPT_CUSTOMER_DATA,
  FETCH_MOBILE_BY_PAN
} from '../../configurations/urls';
import { store } from '../../configureStore';
import config from '../../configurations/index';

const getAuthToken = () => {
  return store.getState().PersonalReducer.authToken;
}

const getAnchorKey = () => {
  return store.getState().PersonalReducer.anchorKey;
}

const getClientToken = () => {
  return config.CLIENT_API_KEY;
}

const getAnchorPortalToken = () => {
  return config.ANCHOR_PORTAL_TOKEN;
}

const fetchPanDetailService = async (data) => {
  const response = await Axios({
    url: FETCH_PAN_URL+"?pan_number="+data.pan_number,
    method: 'GET',
    headers: {
      'api-key': getAnchorKey(),
    }   
  });
  return response.data;
};

const fetchPinCodeDetailService = async (data) => {
  const response = await Axios({
    url: PIN_CODE_URL+"?pin_code="+data.pin_code,
    method: 'GET',
    headers: {
      'api-key': getAnchorKey(),
    }    
      
  });
  return response.data;
};

const createBrowwerService = async (data) => {
  
  const response = await Axios({
    url: BORROWERS_URL,
    method: 'POST',
    headers: {
      'api-key': getAnchorKey(),
    },
    data: data
  });
  return response.data;
};

const authClientService = async (pan,anchorKey) => {
  
  const response = await Axios({
    url: BORROWER_AUTHENTICATION_URL, 
    method: 'POST',
    headers: {
      'api-key': anchorKey || getAnchorKey(),
      'client-api-key': getClientToken(),
    },
    data: {
        "pan_number": pan
    }
  });
  return response.data;
};

const activeListService = async (newToken,anchor_key) => {
  const response = await Axios({
    url: ACTIVATION_LIST_URL, 
    method: 'GET',
    headers: {
      'api-key': anchor_key || getAnchorKey(),
      'Authorization': newToken
    }
  });
  return response.data;
};

const fetchBrowwerService = async (newToken,anchor_key) => {
  const response = await Axios({
    url: BORROWER_DETAIL_URL, 
    method: 'GET',
    headers: {
      'api-key': anchor_key || getAnchorKey(),
      'Authorization': getAuthToken() || newToken
    }
  });
  return response.data;
};


const updateBrowwerService = async (data,borrowerResponse) => {  
  const response = await Axios({
    url: BORROWERS_URL+"/"+borrowerResponse.id,
    method: 'PUT',
    headers: {
      'api-key': getAnchorKey(),
      'Authorization': getAuthToken()
    },
    data: data
  });
  return response.data;
};

const getPanANCHORService = async (pan) => {  
  const response = await Axios({
    url: GET_COMPANY_ANCHOR+"?business_pan="+pan,
    method: 'GET',
    headers: {
      'Accept': 'application/vnd.anchor_dashboard.com; version=3',
      'Content-Type': 'application/json',
      'Authorization': getAnchorPortalToken()
    },
    data: {
      "business_pan": pan
  }
  });
  return response.data;
};

const setAnchorKeyService = async (id) => {  
  const response = await Axios({
    url: GET_ANCHOR_KEY_URL+"?anchor_id="+id,
    method: 'GET',
    headers: {
      'client-api-key': getClientToken(),
    }
  });
  return response.data;
};

const decryptCustomerData = async (data) => {
  const response = await Axios({
    url: DECRYPT_CUSTOMER_DATA,
    method: 'POST',
    headers: {
      'api-key': data.apiKey || getAnchorKey(),
    },
    data: {
      'payload': data.customerData
    }
  });
  return response.data;
};

const fetchMobileByPAN = async (pan) => {
  
  const response = await Axios({
    url: FETCH_MOBILE_BY_PAN+"?pan_number="+pan,
    method: 'GET',
    headers: {
      'api-key': getAnchorKey()
    }
  });
  return response.data;
};

export { 
  fetchPanDetailService,
  createBrowwerService,
  updateBrowwerService,
  authClientService,
  activeListService,
  fetchBrowwerService,
  fetchPinCodeDetailService,
  getPanANCHORService,
  setAnchorKeyService,
  decryptCustomerData,
  fetchMobileByPAN
};
