import {
  STOP_LOADER,
  AA_DATA,
  SAVE_AA_DATA,
  NETBANKING_DATA,
  SAVE_NETBANKING_DATA,
  BS_DATA,
  SAVE_BS_DATA,
  ITR_DATA,
  SAVE_ITR_DATA,
  GET_GST,
  SAVE_GST,
  SUBMIT_GST,
  SAVE_SUBMIT_GST,
  OTP_GST,
  SAVE_OTP_GST,
  OPS_LOAN_CREATE,
  LOS_APP_RELOGIN,
  LOS_APP_STATUS,
  SAVE_LOS_APP_STATUS,
  INC_EMANDATE,
  SAVE_INC_EMANDATE,
  INC_ESIGN,
  SAVE_INC_ESIGN,
  SAVE_DOCUMENT,
  UPLOAD_DOCUMENT,
  CLEAR_LOS_DATA
} from './constants';

function accountAggrAction(data) {
  return {
    type: AA_DATA,
    payload: {
      data
    },
  };
}

function saveAccountAggrAction(response) {
  return {
    type: SAVE_AA_DATA,
    payload: {
      response
    },
  };
}

function nebankingAction(data) {
  return {
    type: NETBANKING_DATA,
    payload: {
      data
    },
  };
}

function saveNebankingAction(response) {
  return {
    type: SAVE_NETBANKING_DATA,
    payload: {
      response
    },
  };
}

function uploadBSAction(data,company_id,anchor_id) {
  return {
    type: BS_DATA,
    payload: {
      data,
      company_id,
      anchor_id
    },
  };
}

function saveUploadBSAction(response,loanResponse) {
  return {
    type: SAVE_BS_DATA,
    payload: {
      response,
      loanResponse
    },
  };
}

function submitITRAction(data,id,anchor_id) {
  return {
    type: ITR_DATA,
    payload: {
      data,
      id,
      anchor_id
    },
  };
}

function saveITRAction(response,responseStatus) {
  return {
    type: SAVE_ITR_DATA,
    payload: {
      response,
      responseStatus
    },
  };
}

function getGSTAction(data) {
  return {
    type: GET_GST,
    payload: {
      data
    },
  };
}

function saveGSTAction(response) {
  return {
    type: SAVE_GST,
    payload: {
      response
    },
  };
}

function submitGSTAction(data,id,anchor_id) {
  return {
    type: SUBMIT_GST,
    payload: {
      data,
      id,
      anchor_id
    },
  };
}

function saveSubmitGSTAction(response,responseStatus) {
  return {
    type: SAVE_SUBMIT_GST,
    payload: {
      response,
      responseStatus
    },
  };
}

function otpGSTAction(data) {
  return {
    type: OTP_GST,
    payload: {
      data
    },
  };
}

function saveotpGSTAction(response) {
  return {
    type: SAVE_OTP_GST,
    payload: {
      response
    },
  };
}


function stopLoader(loaderName) {
  return {
    type: STOP_LOADER,
    payload: {
      loaderName
    },
  };
}

function opsLoanCreateAction(token,data) {
  return {
    type: OPS_LOAN_CREATE,
    payload: {
      token,
      data
    },
  };
}

function losAppReloginAction(token,data) {
  return {
    type: LOS_APP_RELOGIN,
    payload: {
      token,
      data
    },
  };
}

function fetchLoanStatusAction(id,anchor_id) {
  return {
    type: LOS_APP_STATUS,
    payload: {
      id,
      anchor_id
    },
  };
}

function saveLoanStatusAction(response) {
  return {
    type: SAVE_LOS_APP_STATUS,
    payload: {
      response
    },
  };
}


function emandateAction(request) {
  return {
    type: INC_EMANDATE,
    payload: {
      request
    },
  };
}

function saveEmandateAction(response) {
  return {
    type: SAVE_INC_EMANDATE,
    payload: {
      response
    },
  };
}

function esignAction(request) {
  return {
    type: INC_ESIGN,
    payload: {
      request
    },
  };
}

function saveEsignAction(response) {
  return {
    type: SAVE_INC_ESIGN,
    payload: {
      response
    },
  };
}

function saveDocumentAction(response) {
  return {
    type: SAVE_DOCUMENT,
    payload: {
      response
    },
  };
}

function uploadDocumentLOSAction(file,anchor_id,company_id,token) {
  return {
    type: UPLOAD_DOCUMENT,
    payload: {
      file,
      anchor_id,
      company_id,
      token
    },
  };
}

function clearLOSData() {
  return {
    type: CLEAR_LOS_DATA
  };
}


export { 
  accountAggrAction,
  stopLoader,
  saveAccountAggrAction,
  nebankingAction,
  saveNebankingAction,
  uploadBSAction,
  saveUploadBSAction,
  submitITRAction,
  saveITRAction,
  getGSTAction,
  saveGSTAction,
  submitGSTAction,
  saveSubmitGSTAction,
  otpGSTAction,
  saveotpGSTAction,
  opsLoanCreateAction,
  losAppReloginAction,
  saveLoanStatusAction,
  fetchLoanStatusAction,
  emandateAction,
  saveEmandateAction,
  esignAction,
  saveEsignAction,
  saveDocumentAction,
  uploadDocumentLOSAction,
  clearLOSData
};
