const ADD_PERSONAL_DETAILS = 'Mintifi/App/Personal/ADD_PERSONAL_DETAILS';
const STOP_LOADER = 'Mintifi/App/Personal/STOP_LOADER';
const FETCH_PAN_DETAILS = 'Mintifi/App/Personal/FETCH_PAN_DETAILS';
const SAVE_PAN_DETAILS = 'Mintifi/App/Personal/SAVE_PAN_DETAILS';
const CREATE_BORROWER = 'Mintifi/App/Personal/CREATE_BORROWER';
const SAVE_BORROWER = 'Mintifi/App/Personal/SAVE_BORROWER';
const UPDATE_BORROWER = 'Mintifi/App/Personal/UPDATE_BORROWER';
const SAVE_UPDATE_BORROWER = 'Mintifi/App/Personal/SAVE_UPDATE_BORROWER';
const CHANGE_PARTNER = 'Mintifi/App/CHANGE_PARTNER';
const SAVE_PERSONAL_DETAILS = 'Mintifi/App/Personal/SAVE_PERSONAL_DETAILS';
const SAVE_AUTH_PERSONAL_DETAILS = 'Mintifi/App/Personal/SAVE_AUTH_PERSONAL_DETAILS';
const SAVE_OFFER = 'Mintifi/App/Personal/SAVE_OFFER';
const LOAD_AUTH_TOKEN = 'Mintifi/App/Personal/LOAD_AUTH_TOKEN';
const SAVE_AUTH_TOKEN = 'Mintifi/App/Personal/SAVE_AUTH_TOKEN';
const FETCH_PIN_CODE = 'Mintifi/App/Personal/FETCH_PIN_CODE';
const SAVE_PIN_CODE_DETAILS = 'Mintifi/App/Personal/SAVE_PIN_CODE_DETAILS';

const GET_PAN_ANCHOR = 'Mintifi/App/Personal/GET_PAN_ANCHOR';
const SAVE_PAN_ANCHOR = 'Mintifi/App/Personal/SAVE_PAN_ANCHOR';
const GET_ANCHOR_KEY = 'Mintifi/App/Personal/GET_ANCHOR_KEY';
const SAVE_ANCHOR_KEY = 'Mintifi/App/Personal/SAVE_ANCHOR_KEY';
const VALIDATE_LOS_APP = 'Mintifi/App/Personal/VALIDATE_LOS_APP';
const SKIP_LOS = 'Mintifi/App/Personal/SKIP_LOS';
const RELOAD_BNPL = 'Mintifi/App/Personal/RELOAD_BNPL';

const CHECK_BORROWER_AUTH = 'Mintifi/App/Personal/CHECK_BORROWER_AUTH';
const UPDATE_BORROWER_AUTH = 'Mintifi/App/Personal/UPDATE_BORROWER_AUTH';
const SAVE_CUSTOMER_CODE = 'Mintifi/App/Personal/SAVE_CUSTOMER_CODE';

// const SAVE_CUSTOMER_DATA = 'Mintifi/App/Personal/SAVE_CUSTOMER_DATA';
const SAVE_ACTION = 'Mintifi/App/Personal/SAVE_ACTION';
const SAVE_TARGET = 'Mintifi/App/Personal/SAVE_TARGET';
// const SAVE_BORROWER_DETAILS = 'Mintifi/App/Personal/SAVE_BORROWER_DETAILS';
const SAVE_USER_DATA = 'Mintifi/App/Personal/SAVE_USER_DATA';


export {
  STOP_LOADER,
  ADD_PERSONAL_DETAILS,
  FETCH_PAN_DETAILS,
  SAVE_PAN_DETAILS,
  CREATE_BORROWER,
  SAVE_BORROWER,
  UPDATE_BORROWER,
  SAVE_UPDATE_BORROWER,
  CHANGE_PARTNER,
  SAVE_PERSONAL_DETAILS,
  SAVE_AUTH_PERSONAL_DETAILS,
  SAVE_OFFER,
  SAVE_AUTH_TOKEN,
  LOAD_AUTH_TOKEN,
  FETCH_PIN_CODE,
  SAVE_PIN_CODE_DETAILS,
  GET_PAN_ANCHOR,
  SAVE_PAN_ANCHOR,
  GET_ANCHOR_KEY,
  SAVE_ANCHOR_KEY,
  VALIDATE_LOS_APP,
  SKIP_LOS,
  RELOAD_BNPL,
  CHECK_BORROWER_AUTH,
  UPDATE_BORROWER_AUTH,
  SAVE_CUSTOMER_CODE,
  // SAVE_CUSTOMER_DATA,
  SAVE_ACTION,
  SAVE_TARGET,
  // SAVE_BORROWER_DETAILS,
  SAVE_USER_DATA
};
