const CHANGE_STEP = 'Mintifi/App/CHANGE_STEP';
const SET_TOAST = 'Mintifi/App/SET_TOAST';
const STOP_LOADER = 'Mintifi/App/STOP_LOADER';
const LOAD_LOS = 'Mintifi/App/LOAD_LOS';
const LOAD_BNPL = 'Mintifi/App/LOAD_BNPL';
const STEP_COUNT = 'Mintifi/App/STEP_COUNT';
const SAVE_CUSTOMER_DATA = 'Mintifi/App/SAVE_CUSTOMER_DATA'
const SAVE_BORROWER_DETAILS = 'Mintifi/App/Personal/SAVE_BORROWER_DETAILS';
const SAVE_USER_DATA = 'Mintifi/App/Personal/SAVE_USER_DATA'
const CREATE_LEAD = 'Mintifi/App/CREATE_LEAD';
const FETCH_BORROWER_DETAILS = 'Mintifi/App/FETCH_BORROWER_DETAILS'
const SAVE_LEAD_DATA = 'Mintifi/App/SAVE_LEAD_DATA'
const SAVE_LEAD_ERROR_DATA = 'Mintifi/App/SAVE_LEAD_ERROR_DATA'

export {
  CHANGE_STEP,
  SET_TOAST,
  STOP_LOADER,
  LOAD_LOS,
  LOAD_BNPL,
  STEP_COUNT,
  SAVE_CUSTOMER_DATA,
  SAVE_BORROWER_DETAILS,
  SAVE_USER_DATA,
  CREATE_LEAD,
  FETCH_BORROWER_DETAILS,
  SAVE_LEAD_DATA,
  SAVE_LEAD_ERROR_DATA
};
