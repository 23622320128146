import produce from 'immer';
import { IFSC_API,
    SAVE_IFSC_API,
    PENNY_DROP_CALL,
    SAVE_PENNY_DROP_CALL,
    STOP_LOADER,
    START_LOADER,
    SAVE_BANK,
    SAVE_BANK_DATA,
    GET_BANK_DATA,
    GET_BANK_LIST,
    INIT_EMANDATE,
    SAVE_INIT_EMANDATE,
    PHY_NACH,
    SAVE_PHY_NACH,
    SAVE_SET_API_STEP,
    SET_API_STEP,
    SAVE_BORROWER_ACTION,
    SAVE_ENCRYPTED_DATA
} from './constants';

export const initialState = {
  ifscLoader:false,
  pennyLoader:false,
  ifscData:null,
  pennyDropData:null,
  ifscDataFlag:false,
  pennyDropDataFlag:false,
  saveBank: false,
  saveBankLoader: false,
  getBankListLoader:false,
  bankAccount:null,
  initEmandateLoader:false,
  emandateData:null,
  emandateNACH:null,
  setStep:false,
  data_to_encrypt:null,
  encryptedData:null
};

/* eslint-disable default-case, no-param-reassign */
const mobileReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case STOP_LOADER: {
        draft[action.payload.loaderName] = false;
        break;
      }
      case START_LOADER: {
        draft[action.payload.loaderName] = true;
        break;
      }
      case IFSC_API: {
        draft.ifscLoader = true;
        draft.ifscDataFlag = false;
        break;
      }
      case SAVE_IFSC_API: {
        draft.ifscLoader = false;
        draft.ifscDataFlag = true;
        draft.ifscData = action.payload.response;
        break;
      }
      case GET_BANK_LIST: {
        draft.getBankListLoader = true;
        break;
      }
      case GET_BANK_DATA: {
        draft.getBankListLoader = false;
        draft.bankAccount = action.payload.response;
        break;
      }
      case INIT_EMANDATE: {
        draft.initEmandateLoader = true;
        break;
      }
      case SAVE_INIT_EMANDATE: {
        draft.initEmandateLoader = false;
        draft.emandateData = action.payload.response;
        break;
      }
      case SET_API_STEP: {
        draft.initEmandateLoader = true;
        break;
      }
      case SAVE_SET_API_STEP: {
        draft.initEmandateLoader = false;
        draft.setStep = true;
        break;
      }
      case PHY_NACH: {
        draft.initEmandateLoader = true;
        break;
      }
      case SAVE_PHY_NACH: {
        draft.initEmandateLoader = false;
        draft.emandateNACH = action.payload.response;
        break;
      }      
      case SAVE_PENNY_DROP_CALL: {
        draft.pennyLoader = false;
        draft.pennyDropDataFlag = true;
        draft.pennyDropData = action.payload.response;
        break;
      }
      case SAVE_BANK: {
        draft.saveBankLoader = true;
        break;
      }
      case SAVE_BANK_DATA: {
        draft.saveBankLoader = false;
        draft.saveBank = true;
        break;
      }
      case PENNY_DROP_CALL: {
        draft.pennyLoader = true;
         draft.pennyDropDataFlag = false;
        break;
      }
      case SAVE_BORROWER_ACTION: {
        draft.data_to_encrypt = action.payload.data_to_encrypt;
        break;
      }
      case SAVE_ENCRYPTED_DATA:{
        draft.encryptedData = action.payload.data;
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default mobileReducer;