import config from './index';

const {
  ONBOARDING_API_SERVICE,
  LOS_API_SERVICE,
  CREDIT_API_SERVICE,
  OPS_API_SERVICE,
  ANCHOR_API_SERVICE
} = config.URL;

const SEND_OTP_URL = `${ONBOARDING_API_SERVICE}/v1/communications/send_otp`;

const VERIFY_OTP_URL = `${ONBOARDING_API_SERVICE}/v1/communications/verify_otp`;

const FETCH_PAN_URL = `${ONBOARDING_API_SERVICE}/v1/enrich/pan_advance`;

const BORROWERS_URL = `${ONBOARDING_API_SERVICE}/v1/borrowers`;

const BORROWER_AUTHENTICATION_URL = `${ONBOARDING_API_SERVICE}/v1/borrower_authentication`;

const ACTIVATION_LIST_URL = `${ONBOARDING_API_SERVICE}/v1/activation_checklist_items`;

const FETCH_IFSC_DETAILS = `https://ifsc.razorpay.com/`;

const FETCH_OFFER_URL = `${ONBOARDING_API_SERVICE}/v1/credit/avail`;

const BORROWER_DETAIL_URL = `${ONBOARDING_API_SERVICE}/v1/borrowers/details`;

const APPLY_OFFER_URL = `${ONBOARDING_API_SERVICE}/v1/offers/update_status`;

const DOCUMENT_URL = `${ONBOARDING_API_SERVICE}/v1/documents`;

const UPLOAD_DOCUMENT_URL = `${ONBOARDING_API_SERVICE}/v1/documents`;

const PENNY_DROP_URL = `${ONBOARDING_API_SERVICE}/v1/enrich/bank_details`;

const GET_IFSC_URL = `https://ifsc.razorpay.com/`;

const CREATE_BANK_URL = `${ONBOARDING_API_SERVICE}/v1/bank_accounts`;

const PIN_CODE_URL = `${ONBOARDING_API_SERVICE}/v1/enrich/pincode_details`;

const GENERATE_ESIGN_DOCUMENT_URL = `${ONBOARDING_API_SERVICE}/v1/documents/create_esign_agreement`;

const ESIGN_INIT_URL = `${ONBOARDING_API_SERVICE}/v1/esign/init_agreement_document`;
const GET_ESIGN_DATA_URL = `${ONBOARDING_API_SERVICE}/v1/esign/email_request`;

const GET_INIT_EMANDATE_URL = `${ONBOARDING_API_SERVICE}/v1/emandate/create_source`;

const GET_NACH_BANK_URL = `${ONBOARDING_API_SERVICE}/v1/bank_accounts/nach_account`;

const GET_EMANDATE_PHY_NACH_URL = `${ONBOARDING_API_SERVICE}/v1/emandate/create_physical_nach`;

const GET_ANCHOR_KEY_URL = `${ONBOARDING_API_SERVICE}/v1/api_keys`;

const CREDIT_TRANSFER_URL = `${ONBOARDING_API_SERVICE}/v1/credit_limit/`;

const DECRYPT_CUSTOMER_DATA = `${ONBOARDING_API_SERVICE}/v1/borrowers/decrypt_data`;
 
const ENCRYPT_CUSTOMER_DATA = `${ONBOARDING_API_SERVICE}/v1/borrowers/encrypt_data`;

const FETCH_MOBILE_BY_PAN = `${ONBOARDING_API_SERVICE}/v1/borrowers/fetch_mobile_numbers`;


const LOS_AA_URL = `${LOS_API_SERVICE}/v1/account_aggregator/link`;
const LOS_STATUS_URL = `${LOS_API_SERVICE}/v1/loans/`;
const LOS_EMANDATE_URL = `${LOS_API_SERVICE}/v1/repayment/emandate/link.json`;
const LOS_ESIGN_URL = `${LOS_API_SERVICE}/v1/esign/link.json`;
const LOS_DOCUMENT_URL = `${LOS_API_SERVICE}/v1/documents.json`;

const CREDIT_NETBANKING_URL = `${CREDIT_API_SERVICE}/v1/banking/generate-login-payload`;
const CREDIT_BS_URL = `${CREDIT_API_SERVICE}/v1/upload_statements`;
const CREDIT_ITR_URL = `${CREDIT_API_SERVICE}/v1/itr/submit_itr_details`;
const CREDIT_GET_GST_URL = `${CREDIT_API_SERVICE}/v1/gst/get_gstin`;
const CREDIT_OTP_GST_URL = `${CREDIT_API_SERVICE}/v1/gst/otp_generate`;
const CREDIT_SUBMIT_GST_URL = `${CREDIT_API_SERVICE}/v1/gst/gst_data`;

const OPS_TOKEN_URL = `${OPS_API_SERVICE}/v1/clients/login`;
const OPS_CREATE_LOAN_URL = `${OPS_API_SERVICE}/v1/loanapplication`;
const PAN_LOAN_URL = `${OPS_API_SERVICE}/v1/anchors/`;
const COMPANY_LOAN_URL = `${OPS_API_SERVICE}/v1/companies/`;
const OPS_BORROWER_URL = `${OPS_API_SERVICE}/v1/borrower`;

const ANCHOR_SESSIONS_URL = `${ANCHOR_API_SERVICE}/sessions`;
const GET_COMPANY_ANCHOR = `${ANCHOR_API_SERVICE}/distributor_companies/anchor_list`;
const ANCHOR_DISTRIBUTOR_URL = `${ANCHOR_API_SERVICE}/distributor_users/details`;

const CREATE_LEAD_URL = `${ANCHOR_API_SERVICE}/leads`;

export {
  SEND_OTP_URL,
  VERIFY_OTP_URL,
  FETCH_PAN_URL,
  BORROWERS_URL,
  BORROWER_AUTHENTICATION_URL,
  ACTIVATION_LIST_URL,
  FETCH_OFFER_URL,
  BORROWER_DETAIL_URL,
  APPLY_OFFER_URL,
  FETCH_IFSC_DETAILS,
  DOCUMENT_URL,
  UPLOAD_DOCUMENT_URL,
  PENNY_DROP_URL,
  GET_IFSC_URL,
  CREATE_BANK_URL,
  PIN_CODE_URL,
  GET_INIT_EMANDATE_URL,
  ESIGN_INIT_URL,
  GET_ESIGN_DATA_URL,
  GENERATE_ESIGN_DOCUMENT_URL,
  GET_NACH_BANK_URL,
  GET_EMANDATE_PHY_NACH_URL,
  GET_ANCHOR_KEY_URL,
  CREDIT_TRANSFER_URL,
  DECRYPT_CUSTOMER_DATA,
  ENCRYPT_CUSTOMER_DATA,
  FETCH_MOBILE_BY_PAN,

  GET_COMPANY_ANCHOR,
  
  CREDIT_NETBANKING_URL,
  CREDIT_BS_URL,
  CREDIT_ITR_URL,
  CREDIT_GET_GST_URL,
  CREDIT_OTP_GST_URL,
  CREDIT_SUBMIT_GST_URL,

  LOS_AA_URL,
  LOS_STATUS_URL,
  LOS_EMANDATE_URL,
  LOS_ESIGN_URL,
  LOS_DOCUMENT_URL,

  OPS_TOKEN_URL,
  OPS_CREATE_LOAN_URL,
  PAN_LOAN_URL,
  OPS_BORROWER_URL,
  COMPANY_LOAN_URL,

  ANCHOR_SESSIONS_URL,
  ANCHOR_DISTRIBUTOR_URL,
  CREATE_LEAD_URL
};
