const DEV_CONFIG = {
  URL: {
    ONBOARDING_API_SERVICE: 'https://bnpl-qa2.test.mintifi.com/api',
    ONBOARDING_UI_SERVICE: 'https://apply-qa.test.mintifi.com',
    LOS_API_SERVICE: 'https://qa5.mintifi.com/api',
    CREDIT_API_SERVICE: 'https://credit5.api.test.mintifi.com/api',
    OPS_API_SERVICE: 'https://ops5.api.test.mintifi.com',
    ANCHOR_API_SERVICE: 'https://anchors5.test.mintifi.com',
  },
  CLIENT_API_KEY: '6xwpSZ1rf66A2xrjpPcELudys8KDoJMM',
  ANCHOR_PORTAL_TOKEN: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo2OSwidHlwZSI6ImFuY2hvciIsImV4cCI6MTU2ODc4NzM0M30',
  MIX_PANEL_TOKEN: '24fa7e2e536143716fd98bede88554c8',
  LOTUS_PAY_ENV: 'stage',
  PERFIOUS_URL: 'https://demo.perfios.com/KuberaVault/insights/start',
  API_TIMEOUT: 120,
  MODE: 'development',
  ANCHOR_MAIL: "dev+62@mintifi.com",
  ANCHOR_PASSWORD: "Mintifi@123",
  OPS_USER_ID: "d1c042",
  OPS_SECRET_KEY: "alskdjfhg",
  FALLBACK_ANCHOR_ID: 38,
  ACTIVATE_OFFER_DISABLED: [2002,38,2054]
};

const DEV_CONFIG_PREV = {
  URL: {
    ONBOARDING_API_SERVICE: 'https://bnpl-qa1.test.mintifi.com/api',
    ONBOARDING_UI_SERVICE: 'https://apply-test.mintifi.com',
    LOS_API_SERVICE: 'https://qa1.mintifi.com/api',
    CREDIT_API_SERVICE: 'https://credit.api.test.mintifi.com/api',
    OPS_API_SERVICE: 'https://ops.api.test.mintifi.com',
    ANCHOR_API_SERVICE: 'https://anchors1.test.mintifi.com',
  },
  CLIENT_API_KEY: '6xwpSZ1rf66A2xrjpPcELudys8KDoJMM',
  ANCHOR_PORTAL_TOKEN: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo2OSwidHlwZSI6ImFuY2hvciIsImV4cCI6MTU2ODc4NzM0M30',
  MIX_PANEL_TOKEN: '24fa7e2e536143716fd98bede88554c8',
  LOTUS_PAY_ENV: 'stage',
  PERFIOUS_URL: 'https://demo.perfios.com/KuberaVault/insights/start',
  API_TIMEOUT: 120,
  MODE: 'development',
  ANCHOR_MAIL: "dev+62@mintifi.com",
  ANCHOR_PASSWORD: "Mintifi@123",
  OPS_USER_ID: "d1c042",
  OPS_SECRET_KEY: "alskdjfhg",
  FALLBACK_ANCHOR_ID: 38,
  ACTIVATE_OFFER_DISABLED: [2002,38,2054]
};

const PROD_CONFIG = {
  URL: {
    ONBOARDING_API_SERVICE: 'https://bnpl.api.mintifi.com/api',
    ONBOARDING_UI_SERVICE: 'https://apply.mintifi.com',
    LOS_API_SERVICE: 'https://sme.mintifi.com/api',
    CREDIT_API_SERVICE: 'https://credit.api.mintifi.com/api',
    OPS_API_SERVICE: 'https://ops.api.mintifi.com',
    ANCHOR_API_SERVICE: 'https://anchors.mintifi.com',
  },
  CLIENT_API_KEY: 'F56vBpPjC39tEdTaa7DSVXS7iAc4VJsa',
  ANCHOR_PORTAL_TOKEN: 'eyJ0eXAiOiJKV1QiLCJhbGciOxxxxRxAxGxAxS.eyJ1c2VyX2lkIjo2OSwidHlwZSI6ImFuY2hvciIsImV4cCI6MTU2ODc4NzM0M30',
  MIX_PANEL_TOKEN: '7cae290274768df67e49c238868b27e1',
  LOTUS_PAY_ENV: 'production',
  PERFIOUS_URL: 'https://www.perfios.com/KuberaVault/insights/start',
  API_TIMEOUT: 120,
  MODE: 'production',
  ANCHOR_MAIL: "borrower_admin@mintifi.com",
  ANCHOR_PASSWORD: "borrower@admin123",
  OPS_USER_ID: "d9f68e",
  OPS_SECRET_KEY: "2e61c807bb63153d",
  FALLBACK_ANCHOR_ID: 12,
  ACTIVATE_OFFER_DISABLED: [987,12,681],
  GTM_ID: "G-BNDE5TPF47"
};

function getConfig() {
  if (process.env.REACT_APP_ENV === 'development') {
    return DEV_CONFIG;
  }
  else if (process.env.REACT_APP_ENV === 'developmentqa') {
    return DEV_CONFIG_PREV;
  }
  else
    return PROD_CONFIG;
}

export default getConfig();
