/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import appReducer from './app_reducers';
import mobileReducer from './Components/MobileScreen/reducer';
import PersonalReducer from './Components/PersonalScreen/reducer';
import OfferReducer from './Components/Offer/reducer';
import eMandateReducer from './Components/E-mandate/reducer';
import DocumentReducer from './Components/KYC/reducer';
import EsignReducer from './Components/E-sign/reducer';
import IncLimitReducer from './Components/IncreaseLimit/reducer';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    app: appReducer,
    mobileReducer: mobileReducer,
    PersonalReducer: PersonalReducer,
    OfferReducer: OfferReducer,
    eMandateReducer : eMandateReducer,
    DocumentReducer: DocumentReducer,
    EsignReducer: EsignReducer,
    IncLimitReducer: IncLimitReducer,
    ...injectedReducers,
  });
}
