import produce from 'immer';
import { STOP_LOADER,
    AA_DATA,
    SAVE_AA_DATA,
    NETBANKING_DATA,
    SAVE_NETBANKING_DATA,
    BS_DATA,
    SAVE_BS_DATA,
    ITR_DATA,
    SAVE_ITR_DATA,
    GET_GST,
    SAVE_GST,
    SUBMIT_GST,
    SAVE_SUBMIT_GST,
    OTP_GST,
    SAVE_OTP_GST,
    LOS_APP_RELOGIN,
    SAVE_LOS_APP_STATUS,
    INC_EMANDATE,
    SAVE_INC_EMANDATE,
    INC_ESIGN,
    SAVE_INC_ESIGN,
    UPLOAD_DOCUMENT,
    CLEAR_LOS_DATA
} from './constants';

export const initialState = {
  aaLoader:false,
  account_ag_url:null,
  netbankingData: null,
  BSData: null,
  ITRData: null,
  apiLoader: true,
  GetGSTData: null,
  submitGSTData: null,
  losToken:null,
  losLoanStatus: null,
  esignURL:null,
  emandateURL:null,
  losLoanDATA:null,
  otpLoad: false,
  otpResponse: null
};

/* eslint-disable default-case, no-param-reassign */
const documentReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case STOP_LOADER: {
        draft[action.payload.loaderName] = false;
        break;
      }
      case AA_DATA: {
        draft.aaLoader = true;
        break;
      }
      case SAVE_AA_DATA: {
        draft.aaLoader = false;
        draft.account_ag_url = action.payload.response;
        break;
      }
      case NETBANKING_DATA: {
        draft.aaLoader = true;
        break;
      }
      case SAVE_NETBANKING_DATA: {
        draft.aaLoader = false;
        draft.netbankingData = action.payload.response;
        break;
      }
      case BS_DATA: {
        draft.aaLoader = true;
        break;
      }
      case SAVE_BS_DATA: {
        draft.aaLoader = false;
        draft.BSData = action.payload.response;
        draft.losLoanDATA = action.payload.loanResponse;
        break;
      }
      case ITR_DATA: {
        draft.aaLoader = true;
        break;
      }
      case SAVE_ITR_DATA: {
        draft.aaLoader = false;
        draft.losLoanStatus = action.payload.responseStatus;
        draft.ITRData = action.payload.response;
        break;
      }
      case OTP_GST: {
        draft.aaLoader = true;
        break;
      }
      case SAVE_OTP_GST: {
        draft.aaLoader = false;
        draft.otpResponse = action.payload.response;
        draft.otpLoad = true;
        break;
      }
      case GET_GST: {
        draft.apiLoader = true;
        break;
      }
      case SAVE_GST: {
        draft.apiLoader = false;
        draft.GetGSTData = action.payload.response;
        break;
      }
      case SUBMIT_GST: {
        draft.aaLoader = true;
        break;
      }
      case SAVE_SUBMIT_GST: {
        draft.aaLoader = false;
        draft.losLoanStatus = action.payload.responseStatus;
        draft.submitGSTData = action.payload.response;
        break;
      }
      case LOS_APP_RELOGIN: {
        draft.losToken = action.payload.token;
        draft.losLoanDATA = action.payload.data;
        draft.aaLoader = false;
        break;
      }
      case SAVE_LOS_APP_STATUS: {
        draft.losLoanStatus = action.payload.response;
        break;
      }
      case INC_EMANDATE: {
        draft.aaLoader = true;
        break;
      }
      case SAVE_INC_EMANDATE: {
        draft.aaLoader = false;
        draft.emandateURL = action.payload.response;
        break;
      }
      case INC_ESIGN: {
        draft.aaLoader = true;
        break;
      }
      case SAVE_INC_ESIGN: {
        draft.aaLoader = false;
        draft.esignURL = action.payload.response;
        break;
      }
      case UPLOAD_DOCUMENT: {
        draft.aaLoader = true;
        break;
      }
      case CLEAR_LOS_DATA: {
        draft.submitGSTData = null;
        draft.ITRData = null;
        draft.BSData = null;
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default documentReducer;