const STOP_LOADER = 'Mintifi/App/esign/STOP_LOADER';
const GENERATE_ESIGN = 'Mintifi/App/esign/GENERATE_ESIGN';
const INT_ESIGN = 'Mintifi/App/esign/INT_ESIGN';
const SAVE_GENERATE_ESIGN = 'Mintifi/App/esign/SAVE_GENERATE_ESIGN';
const START_LOADER = 'Mintifi/App/esign/START_LOADER';
const SAVE_INT_ESIGN = 'Mintifi/App/esign/SAVE_INT_ESIGN';
const GET_EMAIL_ESIGN ='Mintifi/App/esign/GET_EMAIL_ESIGN';
const SAVE_EMAIL_ESIGN = 'Mintifi/App/SAVE_EMAIL_ESIGN'

export {
    STOP_LOADER,
    GENERATE_ESIGN,
    INT_ESIGN,
    SAVE_GENERATE_ESIGN,
    START_LOADER,
    SAVE_INT_ESIGN,
    GET_EMAIL_ESIGN,
    SAVE_EMAIL_ESIGN
}