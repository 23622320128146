import produce from 'immer';
import { STOP_LOADER,LOAD_DOCUMENT,SAVE_DOCUMENT } from './constants';

export const initialState = {
  documentLoader:false,
  documentList:null
};

/* eslint-disable default-case, no-param-reassign */
const documentReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case STOP_LOADER: {
        draft[action.payload.loaderName] = false;
        break;
      }
      case SAVE_DOCUMENT: {
        draft.documentLoader = false;
        draft.documentList = action.payload.response;
        break;
      }
      case LOAD_DOCUMENT: {
        draft.documentLoader = true;
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default documentReducer;