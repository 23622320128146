import {
  ADD_PERSONAL_DETAILS,
  FETCH_PAN_DETAILS,
  SAVE_PAN_DETAILS,
  CREATE_BORROWER,
  SAVE_BORROWER,
  SAVE_UPDATE_BRO,  
  STOP_LOADER,
  UPDATE_BORROWER,
  SAVE_UPDATE_BORROWER,
  CHANGE_PARTNER,
  SAVE_PERSONAL_DETAILS,
  SAVE_AUTH_PERSONAL_DETAILS,
  SAVE_OFFER,
  LOAD_AUTH_TOKEN,
  SAVE_AUTH_TOKEN,
  FETCH_PIN_CODE,
  SAVE_PIN_CODE_DETAILS,
  GET_PAN_ANCHOR,
  SAVE_PAN_ANCHOR,
  GET_ANCHOR_KEY,
  SAVE_ANCHOR_KEY,
  VALIDATE_LOS_APP,
  SKIP_LOS,
  RELOAD_BNPL,
  CHECK_BORROWER_AUTH,
  UPDATE_BORROWER_AUTH,
  SAVE_CUSTOMER_CODE,
  SAVE_ACTION,
  SAVE_TARGET
} from './constants';

function addPersonalDetailAction(data) {
  return {
    type: ADD_PERSONAL_DETAILS,
    payload: {
      data
    },
  };
}

function checkBorrowerAuth(data,anchorId){
  return {
    type: CHECK_BORROWER_AUTH,
    payload: {
      data,
      anchorId
    },
  };
}

function updateBorrowerAuth(data){
  return {
    type: UPDATE_BORROWER_AUTH,
    payload: {
      data
    },
  };
}

function savePersonalDetailAction(data) {
  return {
    type: SAVE_PERSONAL_DETAILS,
    payload: {
      data
    },
  };
}

function savePersonalDetailAuthAction(data,auth,activeList,browwerDetail) {
  return {
    type: SAVE_AUTH_PERSONAL_DETAILS,
    payload: {
      data,
      auth,
      activeList,
      browwerDetail
    },
  };
}

function loadAuthTokenAction(data) {
  return {
    type: LOAD_AUTH_TOKEN,
    payload: {
      data,
    },
  };
}

function saveAuthTokenAction(auth) {
  return {
    type: SAVE_AUTH_TOKEN,
    payload: {
      auth,
    },
  };
}

function fetchPanDetailAction(data) {
  return {
    type: FETCH_PAN_DETAILS,
    payload: {
      data
    },
  };
}

function fetchPinCodeAction(data) {
  return {
    type: FETCH_PIN_CODE,
    payload: {
      data
    },
  };
}

function savePanDetailAction(pan_number,response) {
  return {
    type: SAVE_PAN_DETAILS,
    payload: {
      pan_number,
      response
    },
  };
}

function savePinCodeDetailAction(pin_code,response) {
  return {
    type: SAVE_PIN_CODE_DETAILS,
    payload: {
      pin_code,
      response
    },
  };
}

function saveOfferAction(response) {
  return {
    type: SAVE_OFFER,
    payload: {
      response
    },
  };
}


function createBorrowerAction(data) {
  return {
    type: CREATE_BORROWER,
    payload: {
      data
    },
  };
}

function saveBorrowerAction(response) {
  return {
    type: SAVE_BORROWER,
    payload: {
      response
    },
  };
}

function stopLoader(loaderName) {
  return {
    type: STOP_LOADER,
    payload: {
      loaderName
    },
  };
}

function updateBrowwerAction(data,borrowerResponse){
  return {
    type: UPDATE_BORROWER,
    payload: {
      data,
      borrowerResponse
    },
  };
}

function saveBrowwerAction(data) {
  return {
    type: SAVE_UPDATE_BORROWER,
    payload: {
      data
    },
  };
}

function changePartners(partners) {
  return {
    type: CHANGE_PARTNER,
    payload: {
      partners,
    },
  };
}

function panAnchorAction(pan) {
  return {
    type: GET_PAN_ANCHOR,
    payload: {
      pan,
    },
  };
}

function savePanAnchorAction(response,pan) {
  return {
    type: SAVE_PAN_ANCHOR,
    payload: {
      response,
      pan
    },
  };
}

function setAnchorKeyAction(id) {
  return {
    type: GET_ANCHOR_KEY,
    payload: {
      id,
    },
  };
}

function saveAnchorKeyAction(auth) {
  return {
    type: SAVE_ANCHOR_KEY,
    payload: {
      auth,
    },
  };
}

function setCustomerCode(auth) {
  return {
    type: SAVE_CUSTOMER_CODE,
    payload: {
      auth,
    },
  };
}


function setAction(action) {
  return {
    type: SAVE_ACTION,
    payload: {
      action,
    },
  };
}

function setTarget(target) {
  return {
    type: SAVE_TARGET,
    payload: {
      target,
    },
  };
}

function validateLOSAppAction(pan,anchor_id) {
  return {
    type: VALIDATE_LOS_APP,
    payload: {
      pan,
      anchor_id
    },
  };
}

function skipLOSAction() {
  return {
    type: SKIP_LOS
  };
}

function savebnplAuthAction(borowwer,actlist,token,anchor_key) {
  return {
    type: RELOAD_BNPL,
    payload: {
      borowwer,
      actlist,
      token,
      anchor_key
    },
  };
}

export { 
  addPersonalDetailAction,
  fetchPanDetailAction,
  savePanDetailAction,
  createBorrowerAction,
  saveBorrowerAction,
  stopLoader,
  updateBrowwerAction,
  saveBrowwerAction,
  changePartners,
  savePersonalDetailAction,
  savePersonalDetailAuthAction,
  saveOfferAction,
  saveAuthTokenAction,
  loadAuthTokenAction,
  fetchPinCodeAction,
  savePinCodeDetailAction,
  panAnchorAction,
  savePanAnchorAction,
  setAnchorKeyAction,
  saveAnchorKeyAction,
  setCustomerCode,
  validateLOSAppAction,
  skipLOSAction,
  savebnplAuthAction,
  checkBorrowerAuth,
  updateBorrowerAuth,
  setAction,
  setTarget
};
