import React,{useEffect} from 'react';
import { Col, Row,Typography,Input,Button,Form,Spin} from 'antd';
import OtpInput from 'react-otp-input';
import { CheckOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useInjectSaga } from '../../injectSaga';
//import { useInjectReducer } from '../../injectReducer';
import saga from './saga';
//import reducer from './reducer';
import { sendOTPAction,verifyOTPAction,anchorSessionSendAction} from "./actions";
import { stepAction } from '../../app_actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { redirectMe } from '../../configurations/utils';

import './index.css';
import { Mixpanel } from '../../configurations/mixpanel';
const { Paragraph } = Typography;

function MobileScreen() {
  useInjectSaga({ key: 'mobileSaga', saga });
  //useInjectReducer({ key: 'mobileReducer', reducer });
  const pageLoaded = React.useRef(true);

  const [mobileCheck,setMobileCheck] = React.useState(false);

  const [mobile,setMobile] = React.useState(null);
  const [mobileErr,setMobileErr] = React.useState(null);

  const [otp,setOTP] = React.useState(null);
  const [otpErr,setOPTError] = React.useState(null);

  const [otpTimer,setOtpTimer] = React.useState(0);
  const [sendOtpFlag,setSendOtpFlag] = React.useState(0);
  const dispatch = useDispatch();
  const history = useNavigate();
  const { otpLoader,otpResponse,userResponse,distributorStatus,distributorLoader} = useSelector(state => state.mobileReducer ?? []);
  const { losToken,losLoanDATA } = useSelector(state => state.IncLimitReducer ?? []);
  const { persoanalDetails, loginType, personalLoader, activeList,panAnchorLoader,panAnchor,anchorKey,anchorKeyLoader,skipLOS,browwerDetails,authToken} = useSelector(state => state.PersonalReducer ?? []);
  const { userData, decrypt_data } = useSelector(state => state.app ?? []);

  React.useEffect(()=>{
    if(decrypt_data && decrypt_data.borrower && decrypt_data.borrower.mobile_number){
      var pattern = /^[6789]\d{9}$/;
      if(pattern.test(decrypt_data.borrower.mobile_number)) {
        setMobile(decrypt_data.borrower.mobile_number);
        sendOTPFn(decrypt_data.borrower.mobile_number)
      }  
    }
  },[decrypt_data && decrypt_data.borrower && decrypt_data.borrower.mobile_number]);

  React.useEffect(()=>{
    window.scrollTo(0, 0);
    if(pageLoaded.current) {
      pageLoaded.current = false;
      dispatch(stepAction(0));
      Mixpanel.track('MOBILE_SCREEN_LOADED');
    }    
  },[]);

  React.useEffect(() => {
    if(userData && userData.mobile_number){
      var pattern = /^[6789]\d{9}$/;
      if(pattern.test(userData.mobile_number)) {
        setMobile(userData.mobile_number);
        sendOTPFn(userData.mobile_number)
      }  
    }
  },[userData && userData.mobile_number])

  React.useEffect(() => {
    if(losToken && losLoanDATA) {
      history('/increase-limit')
    }      
  },[losToken,losLoanDATA])

  React.useEffect(() => {
    if(anchorKey && browwerDetails && activeList && authToken) {
      let getCurrentStep = redirectMe(activeList);
      history(getCurrentStep);
    }
  },[anchorKey,browwerDetails,activeList,authToken]); 

  const validateME = () => {
    
    setMobileErr(null);
    setOPTError(null);

    if(!mobileCheck) {
      var pattern = /^[6789]\d{9}$/;
      
    if(mobile && pattern.test(mobile)) {
        sendOTPFn()
      }
      else {
        setMobileErr("Please enter valid mobile number.");        
      }
    }
    else {
      if(otp && otp.length === 6) {
        dispatch(verifyOTPAction({mobile_number: mobile,otpResponse,otp}));
      }
      else {
        setOPTError("Please enter valid OTP.")
      }
    }
  }

  const sendOTPFn = (a) => {
    setSendOtpFlag(sendOtpFlag + 1);
    let newTimer = 30;
    let intervalID = setInterval(() => {
      let tempTime = newTimer - 1;
      newTimer = tempTime;
      if(tempTime === 0) {
        setOtpTimer(null);
        clearInterval(intervalID);
      }
      else {
        setOtpTimer(tempTime);
      }      
    }, 1000)
    dispatch(sendOTPAction({mobile_number: a ? a : mobile}));
  }

  React.useEffect(()=>{
    if(otpResponse && otpResponse.otp_reference_id){
      setMobileCheck(true);
    }
  },[otpResponse])

  React.useEffect(()=>{
    if(userResponse && userResponse.is_otp_verified){
      if(anchorKey) {
        history('/personal-details');
      }
      else {
        dispatch(anchorSessionSendAction({data: userResponse.recipient}));  
      }      
    }
  },[userResponse])

  React.useEffect(() => {
    if(distributorStatus) {
      history('/personal-details');
    }
  },[distributorStatus])

  const checkMobile = (value) => {
    var pattern = /^[6789]\d{9}$/;
    setMobile(value);
    setMobileErr(null)
    if(value && pattern.test(value) ){
      setMobileErr(null);
    }
    else if(!pattern.test(mobile) && value.length === 10){
      setMobileCheck(false);
      setMobileErr("Please enter valid mobile number.");
    }
  }
  return (
    <>
    {distributorLoader ? 
      <Row justify="center" style={{ marginTop: '150px' }}>
        <Col>
          <Spin size="large" />
        </Col>
      </Row>
      :
      <>
    <Typography.Title className="mnt-color-blue1" level={4}>Let's start with your mobile number.</Typography.Title>
    <Paragraph className="mnt-color-blue2">This number will be used to fetch your documents and details, securely!</Paragraph>
    <Row>
      <Col xs={{span: 24}} lg={{span: 18}}>
        <Typography.Text style={{fontSize: '11px',color: 'var(--color4)'}}>Mobile Number</Typography.Text>        
        <Input disabled={mobileCheck} addonBefore="+91" size="small" value={mobile} onChange={(event) => checkMobile(event.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} maxLength={10} className="mnt-input" placeholder="" suffix={mobileCheck ? <CheckOutlined style={{color: '#63d4a4'}}
          />: ''}/>
          {mobileErr &&
            <Typography.Text className="errorClass">{mobileErr}</Typography.Text> 
          }
       </Col>
       {mobileCheck &&
       <Col xs={{span: 24}} lg={{span: 18}}>
          <OtpInput containerStyle={{justifyContent: 'space-between'}} className="mnt-otp-ui" numInputs={6} isInputNum={true} onChange={(otpv) => setOTP(otpv)} value={otp} />
          {otpErr &&
            <Typography.Text className="errorClass">{otpErr}</Typography.Text> 
          }
       </Col>
       }
       {mobileCheck &&
        <Col xs={{span: 24}} lg={{span: 24}} style={{marginTop: '7px'}}>
         <Row>
           <Col xs={{span: 12}} lg={{span: 12}} >
           {sendOtpFlag < 3 &&
            <>
              {otpTimer === null ?
               <Typography.Text style={{color: 'var(--color2)',fontSize:'12px',cursor: 'pointer'}} onClick={() => sendOTPFn()} >Re-send OTP</Typography.Text>
              :
              <>
                <Typography.Text style={{color: 'var(--color4)',fontSize:'12px',cursor: 'wait'}}>Re-send OTP in 30 seconds</Typography.Text>
                <br/>
               <Typography.Text style={{color: 'var(--color4)',fontSize:'12px',cursor: 'pointer'}} onClick={() => sendOTPFn()} >Remaining {3 - sendOtpFlag} attempt</Typography.Text>
              </>
              }
            </>
           }
           </Col>
            {otpTimer !== null &&
             <Col xs={{span: 12}} lg={{span: 6}} style={{textAlign: 'right'}}>
                 <Typography.Text style={{color: 'var(--color3)',fontSize:'12px'}}>00.{otpTimer < 10 && "0"}{otpTimer}</Typography.Text>
             </Col>
            }
         </Row>
         </Col>
      }
      <Col xs={{span: 24}} lg={{span: 14}} className="mnt-btn-cnt">
          {mobileCheck ? 
            <Button loading={otpLoader || distributorLoader}
              size="large"
              onClick={() => validateME()}
              className="mnt-btn"
              type={(otp && otp.length === 6) && (otpErr === null) ? "primary" : 'disabled'}
              disabled={(otp === null || otp.length < 6 || otpErr)}
            >VERIFY</Button>
          :
            <Button
              loading={otpLoader}
              size="large"
              onClick={() => validateME()}
              type={(mobile && mobile.length === 10) && (mobileErr === null) ? "primary" : 'disabled'}
              disabled={(mobile === null || mobile.length < 10 || mobileErr)}
              className="mnt-btn">GET OTP</Button>
          }
       </Col>
     </Row>
     </>          
    }
     </>
  );
}

export default MobileScreen;
