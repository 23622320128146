import {
  SET_TOAST,
  LOAD_LOS,
  LOAD_BNPL,
  STOP_LOADER,
  STEP_COUNT,
  SAVE_CUSTOMER_DATA,
  SAVE_BORROWER_DETAILS,
  SAVE_USER_DATA,
  CREATE_LEAD,
  FETCH_BORROWER_DETAILS,
  SAVE_LEAD_DATA,
  SAVE_LEAD_ERROR_DATA
} from './app_constants';

function setToastAction(toast) {
  return {
    type: SET_TOAST,
    payload: {
      toast
    },
  };
}

function loadLOS(token,anchor_id,company_id) {
  return {
    type: LOAD_LOS,
    payload: {
      token,
      anchor_id,
      company_id
    },
  };
}

function stopLoader(loaderName) {
  return {
    type: STOP_LOADER,
    payload: {
      loaderName
    },
  };
}

function loadBNPL(token,anchor_key) {
  return {
    type: LOAD_BNPL,
    payload: {
      token,
      anchor_key
    },
  };
}

function stepAction(data) {
  return {
    type: STEP_COUNT,
    payload: {
      data
    }
  }
}

function getDecryptedData(cust_data) {
  console.log("act",cust_data)
  return {
    type: SAVE_CUSTOMER_DATA,
    payload: {
      cust_data,
    },
  };
}

function saveDecryptedData(decrypt_data) {
  return {
    type: SAVE_BORROWER_DETAILS,
    payload: {
      decrypt_data
    },
  };
}

function saveUserData(data) {
  return {
    type: SAVE_USER_DATA,
    payload: {
      data
    },
  };
}

function createLead(data) {
  return {
    type: CREATE_LEAD,
    payload: {
      data
    }
  }
}

function fetchBorrowerDetails() {
  return {
    type: FETCH_BORROWER_DETAILS,
  }
}

function saveLeadData(data) {
  return {
    type: SAVE_LEAD_DATA,
    payload: {
      data
    }
  }
}

function saveLeadErrorData(data) {
  return {
    type: SAVE_LEAD_ERROR_DATA,
    payload: {
      data
    }
  }
}

export { 
  setToastAction,
  loadLOS,
  loadBNPL,
  stopLoader,
  stepAction,
  getDecryptedData,
  saveDecryptedData,
  saveUserData,
  createLead,
  fetchBorrowerDetails,
  saveLeadData,
  saveLeadErrorData
};
