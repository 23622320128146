import {
  SEND_OTP,
  STOP_LOADER,
  SAVE_OTP,
  VERIFY_OTP,
  VERIFY_OTP_RESPONSE,
  CLEAR_OTP,
  ANCHOR_SESSIONS_SEND,
  DISTRIBUTOR_DETAILS,
  DISTRIBUTOR_LOADER
} from './constants';

function sendOTPAction(data) {
  return {
    type: SEND_OTP,
    payload: {
      data
    },
  };
}

function clearOTP() {
  return {
    type: CLEAR_OTP,
    payload: {}
  }
}

function saveOTPResponseAction(response) {
  return {
    type: SAVE_OTP,
    payload: {
      response
    },
  };
}

function verifyOTPAction(data) {
  return {
    type: VERIFY_OTP,
    payload: {
      data
    },
  };
}

function verifyOTPResponseAction(response) {
  return {
    type: VERIFY_OTP_RESPONSE,
    payload: {
      response
    },
  };
}

function stopLoader(loaderName) {
  return {
    type: STOP_LOADER,
    payload: {
      loaderName
    },
  };
}

function anchorSessionSendAction(data) {
  return {
    type: ANCHOR_SESSIONS_SEND,
    payload: data,
  };
}

function distributorDetailsAction(data) {
  return {
    type: DISTRIBUTOR_DETAILS,
    payload: {data},
  };
}

function distributorLoaderAction(data) {
  return {
    type: DISTRIBUTOR_LOADER,
    payload: {data},
  };
}

export { 
  sendOTPAction,
  stopLoader,
  saveOTPResponseAction,
  verifyOTPAction,
  verifyOTPResponseAction,
  clearOTP,
  anchorSessionSendAction,
  distributorDetailsAction,
  distributorLoaderAction
};
