/* eslint-disable no-empty */
import { call, put, takeLatest } from 'redux-saga/effects';

import { sendOTPService,verifyOTPService,anchorSessionService,distributorUserService,loanAccountDetailService,opsTokenService} from './services';
import { stopLoader,saveOTPResponseAction,verifyOTPResponseAction,distributorDetailsAction,distributorLoaderAction } from './actions';
import { setToastAction } from '../../app_actions';
import { deserialize } from 'jsonapi-fractal'
import { Mixpanel } from '../../configurations/mixpanel';
import { SEND_OTP,VERIFY_OTP,ANCHOR_SESSIONS_SEND } from './constants';
import {getErrMessage} from '../../configurations/utils';

function* sendOTPSaga(props) {
  try {
    const response = yield call(sendOTPService,props.payload.data);

    if(response && response.data) {
      var data = deserialize(response);
      Mixpanel.track('MOBILE_SCREEN_SEND_OTP_SUCCESS', {
            request_data: props.payload.data,
            response: data
      });
      yield put(saveOTPResponseAction(data));
    }

  } catch(err) {
    Mixpanel.track('MOBILE_SCREEN_SEND_OTP_ERROR', {
        request_data: props.payload.data,
        error: err
    });
    if(err && err.data){ 
      yield put(setToastAction({status: "error",message: getErrMessage(err.data)}))     
    }
    yield put(stopLoader('otpLoader'))
  }
}

function* verifyOTPSaga(props) {
  try {
    const response = yield call(verifyOTPService,props.payload.data);

    if(response && response.data) {
      
      Mixpanel.identify(props.payload.data.mobile_number);
      
      var data = deserialize(response);
      Mixpanel.track('MOBILE_SCREEN_VERIFY_OTP_SUCCESS', {
            request_data: props.payload.data,
            response: data
      });
      console.log(data);
      yield put(verifyOTPResponseAction(data));
    }

  } catch(err) {
    
    Mixpanel.track('MOBILE_SCREEN_VERIFY_OTP_ERROR', {
        request_data: props.payload.data,
        error: err
    });
    
    if(err && err.data){ 
      yield put(setToastAction({status: "error",message: getErrMessage(err.data)}))     
    }
    yield put(stopLoader('otpLoader'))
  }
}


function* anchorSessionSaga(props) {
  
  try {
    yield put(distributorLoaderAction(true));
    const response = yield call(anchorSessionService);

    Mixpanel.track('MOBILE_SCREEN_ANCHOR_SESSION_SUCCESS', {
            request_data: props.payload.data,
            response: response
    });

    if (response && response.auth_token) {

      var authToken = response.auth_token;
      yield put(distributorLoaderAction(false));

      try {

        yield put(distributorLoaderAction(true));
        const responseA = yield call(opsTokenService, authToken);

        Mixpanel.track('MOBILE_SCREEN_OPS_TOKEN_SUCCESS', {
                request_data: props.payload.data,
                response: responseA
        });

        if (responseA && responseA.response && responseA.response.auth && responseA.response.auth.token) {
          
          yield put(distributorLoaderAction(false));
          var opsLoginAuth = responseA.response.auth.token;

          try {

            yield put(distributorLoaderAction(true));
            const responseB = yield call(distributorUserService, authToken, props.payload.data);

            Mixpanel.track('MOBILE_SCREEN_ANCHOR_BOROWWER_SUCCESS', {
                    request_data: props.payload.data,
                    response: responseB
            });

            if (responseB && responseB.data && responseB.data.id && responseB.data.attributes && responseB.data.attributes.user_token) {
              
              yield put(distributorLoaderAction(false));
              
              try {
                yield put(distributorLoaderAction(true));
                const responseC = yield call(loanAccountDetailService, opsLoginAuth, responseB.data.id);
                
                Mixpanel.track('MOBILE_SCREEN_OPS_LOAN_SUCCESS', {
                        request_data: props.payload.data,
                        borrower_id: responseB.data.id,
                        response: responseC
                });

                const dataC = deserialize(responseC)
                
                if (dataC && dataC.id && dataC.loan_application && dataC.loan_application.length > 0) {
                  
                  Mixpanel.track('MOBILE_SCREEN_DISBURSED', {
                          request_data: props.payload.data,
                          borrower_id: responseB.data.id,
                          response: responseC
                  });

                  yield put(distributorLoaderAction(false));
                  yield put(setToastAction({ status: "error", message: getErrMessage({ error: "You already have loan application, Please try to login https://customer.mintifi.com" }) }))
                  setTimeout(function(){
                      window.location.href = "https://customer.mintifi.com";
                  }, 2000);
                }
                else {
                  yield put(distributorDetailsAction({}));
                  yield put(distributorLoaderAction(false));
                }

              }
              catch (err3) {
                Mixpanel.track('MOBILE_SCREEN_OPS_LOAN_ERROR', {
                        request_data: props.payload.data,
                        borrower_id: responseB.data.id,
                        error: err3
                });
                yield put(distributorDetailsAction(err3));
                yield put(distributorLoaderAction(false));
              }
            }

          }
          catch (err2) {
            Mixpanel.track('MOBILE_SCREEN_ANCHOR_BOROWWER_ERROR', {
                    request_data: props.payload.data,
                    error: err2
            });
            yield put(distributorDetailsAction(err2));
            yield put(distributorLoaderAction(false));
          }
        }

      }
      catch (err1) {
        Mixpanel.track('MOBILE_SCREEN_OPS_TOKEN_ERROR', {
                request_data: props.payload.data,
                error: err1
        });
        yield put(distributorDetailsAction(err1));
        yield put(distributorLoaderAction(false));
      }
    }

  } catch (err) {
    Mixpanel.track('MOBILE_SCREEN_ANCHOR_SESSION_ERROR', {
            request_data: props.payload.data,
            error: err
    });
    yield put(distributorDetailsAction(err));
    yield put(distributorLoaderAction(false));
  }
}

export default function* appSaga() {
  yield takeLatest(SEND_OTP, sendOTPSaga);
  yield takeLatest(VERIFY_OTP, verifyOTPSaga);
  yield takeLatest(ANCHOR_SESSIONS_SEND, anchorSessionSaga);
}
