import React, { useEffect,Suspense } from "react";
import { Col, Row,Typography,Steps,Input,Button,Spin,Skeleton,Image } from 'antd';
import { ContactsOutlined, PhoneOutlined } from '@ant-design/icons';
import { IoMdContact } from "react-icons/io"
import { RiCustomerService2Line } from "react-icons/ri"
import OtpInput from 'react-otp-input';
import { BrowserRouter as Router, redirect, Route, Routes} from "react-router-dom";
import {setAction,setTarget,setCustomerCode } from '../Components/PersonalScreen/actions';
import {getDecryptedData} from '../app_actions';
import logo from '../images/logo.png';
import { Mixpanel } from '../configurations/mixpanel';
import MobileScreen from '../Components/MobileScreen';
import { useDispatch, useSelector } from 'react-redux';
import './Layout.css';
import AddGST from "../Components/PersonalScreen/AddGST";
import saga from '../saga';
import { useInjectSaga } from '../injectSaga';

const PersonalScreen = React.lazy(() => import('../Components/PersonalScreen'));
const SelectGST = React.lazy(() => import('../Components/PersonalScreen/SelectGST'));
const Esign = React.lazy(() => import('../Components/E-sign'));
const EsignCallback = React.lazy(() => import('../Components/E-sign/esignCallback'));
const EsignRequest = React.lazy(() => import('../Components/E-sign/esignRequest'));
const EsignPopup = React.lazy(() => import('../Components/E-sign/esignPopup'));

const Emandate = React.lazy(() => import('../Components/E-mandate'));
const EmandateList = React.lazy(() => import('../Components/E-mandate/List'));
const EmandateUpload = React.lazy(() => import('../Components/E-mandate/Upload'));
const EmandateSuccess = React.lazy(() => import('../Components/E-mandate/Success'));
const EmandateUploadSuccess = React.lazy(() => import('../Components/E-mandate/UploadSuccess'));
const EmandateCallback = React.lazy(() => import('../Components/E-mandate/emandateCallback'));
const LeadSuccess = React.lazy(() => import('../Components/E-mandate/LeadSuccess'));

const Document = React.lazy(() => import('../Components/IncreaseLimit/document'));


const FinalSuccess = React.lazy(() => import('../Components/E-mandate/FinalSuccess'));


const CoBorrower = React.lazy(() => import('../Components/PersonalScreen/CoBorrower'));
const Offer = React.lazy(() => import('../Components/Offer'));
const OfferIncLimit = React.lazy(() => import('../Components/Offer/IncLimit'));

const NotEligible = React.lazy(() => import('../Components/Offer/NotEligible'));
const Payment = React.lazy(() => import('../Components/Offer/Payment'));
const KYC = React.lazy(() => import('../Components/KYC'));
const IncreaseLimit = React.lazy(() => import('../Components/IncreaseLimit/Init'));
const IncreaseLimitBank = React.lazy(() => import('../Components/IncreaseLimit'));
const AccountA = React.lazy(() => import('../Components/IncreaseLimit/aa'));
const Netbanking = React.lazy(() => import('../Components/IncreaseLimit/Netbanking'));
const BankStatement = React.lazy(() => import('../Components/IncreaseLimit/BankStatement'));
const GST = React.lazy(() => import('../Components/IncreaseLimit/GST'));
const ITR = React.lazy(() => import('../Components/IncreaseLimit/ITR'));

const IncreaseLimitEmandate = React.lazy(() => import('../Components/IncreaseLimit/emandate'));
const IncreaseLimitEsign = React.lazy(() => import('../Components/IncreaseLimit/esign'));

const { Paragraph } = Typography;
const { Step } = Steps;

const imageList = ["RivJskY7vAxnKWoA"];

function App({defaultdata}) {
  useInjectSaga({ key: 'appSaga', saga });
  const dispatch = useDispatch();

  const [curStep,setcurStep] = React.useState(0);
  const {stepCount,decrypt_data} = useSelector(state => state.app ?? []);
  const { anchorKey, action } = useSelector(state => state.PersonalReducer ?? []);


  React.useEffect(() => {
    console.log(defaultdata)
    if(defaultdata.target){
      dispatch(setTarget(defaultdata.target))
    }

    if(defaultdata.action){
      dispatch(setAction(defaultdata.action))
    }

    if(defaultdata && defaultdata.customerData){
      dispatch(getDecryptedData(defaultdata))
    }

    if(defaultdata) {
      Mixpanel.track('DEFAULT_DATA_APP_SCREEN', {
        defaultdata: defaultdata
      });
    }
  },[defaultdata])


  React.useEffect(() => {
    if(decrypt_data && decrypt_data.borrower && decrypt_data.borrower.customer_codes_attributes && decrypt_data.borrower.customer_codes_attributes[0] && decrypt_data.borrower.customer_codes_attributes[0].code) {
      dispatch(setCustomerCode(decrypt_data.borrower.customer_codes_attributes[0].code));
    }
  },[decrypt_data]);
  
  useEffect(() => {
      Mixpanel.track('VISITED_APP_SCREEN');
  },[])


  useEffect(()=>{
    if(window.location.pathname === '/'){
      localStorage.removeItem('los_auth_token');
      localStorage.removeItem('los_anchor_id');
      localStorage.removeItem('los_company_id');
      localStorage.removeItem('bnpl_auth_token');
      localStorage.removeItem('bnpl_anchor_key');
      localStorage.removeItem('is_bnpl_anchor');
    }
  },[window.location.pathname]);

  const redirectHome = () => {
    localStorage.removeItem('los_auth_token');
    localStorage.removeItem('los_anchor_id');
    localStorage.removeItem('los_company_id');
    localStorage.removeItem('bnpl_auth_token');
    localStorage.removeItem('bnpl_anchor_key');
    window.open("/",'_self');
  }

  return (
    <div className="mnt-container">

      <Row >
        <Col xs={{span: 24,offset: 0}} lg={{span: 14,offset: 5}} className="mnt-container-div">

          <Row align="middle" className="mnt-container-logo">
              <Col xs={{span: 11,offset: 1}} lg={{span: 18,offset: 0}} className="mnt-container-logo-cont" style={{cursor: 'pointer'}}>
                <div style={{display: 'flex',alignItems: 'center'}}>
                <img alt="mintifi-logo" src={logo} onClick={()=>redirectHome()}/>
                {process.env.REACT_APP_ENV === 'development' &&
                    <div style={{fontSize: '15px',color:'#0a3cf1'}}>UAT</div>
                }
                </div>
              </Col>
              {anchorKey && imageList.indexOf(anchorKey) !== -1 &&
              <Col xs={{span: 11,offset: 0}} lg={{span: 6,offset: 0}} className="mnt-container-logo-cnct" >
                <Image height="40px" preview={false} src={"anchor/"+anchorKey+".png"}/>
              </Col>
              }
          </Row>
          <Row className="mnt-container-content">
              <Col xs={{span: 24}} lg={{span: 8}} className="mnt-container-content-step">
                <Row>
                  <Col xs={{span: 24}} lg={{span: 24}}>
                    <Typography.Title className="mnt-color-blue1" level={4}>Simplify your <br className="mnt-mobile-hidden" />Line-of-credit</Typography.Title>
                    <Paragraph className="mnt-color-blue2">in 3 easy steps!</Paragraph>
                  </Col>
                  <Col xs={{span: 0}} lg={{span: 24}} style={{textAlign:'right'}}>
                    <div className="mnt-container-content-step-org-cont">
                      <Steps className="mnt-container-content-step-org" current={stepCount} direction="vertical">
                          <Step title="Personal & GST Details" />
                          <Step title="Offer Limit" />
                          <Step title="KYC & Approvals" />
                      </Steps>
                    </div>
                  </Col>
                  <Col xs={{span: 0}} lg={{span: 24}} style={{position: 'absolute', bottom: '14px'}}>
                    <Typography.Link href="tel:+91 9004117000" style={{color: 'var(--color1)',fontSize: '15px', display: 'flex'}}>
                      <span style={{paddingTop: '1px', background: 'var(--color1)',borderRadius: '25px',width: '24px',height:'24px',display:'inline-block',textAlign: 'center'}}>
                        <RiCustomerService2Line style={{color: '#fff', fontSize: '16px', marginTop: '3px'}} />
                      </span>
                      <span>&nbsp;+91 9004117000</span>
                    </Typography.Link>
                  </Col>
                </Row>  
              </Col>
              <Col xs={{span: 24}} lg={{span: 16}} className="mnt-mobile-page">
                  <div className="mnt-mobile-page-content">
                  <Router>
                  <Suspense fallback={<Skeleton paragraph={{rows: 10 }} active/>}>
                        <Routes>
                          <Route path="/" element={<MobileScreen />} />
                          <Route path="/personal-details" element={<PersonalScreen/>} />
                          <Route path="/select-gst" element={<SelectGST />} />
                          <Route path="/add-gst" element={<AddGST />} />
                          <Route path="/co-borrower" element={<CoBorrower />} />

                          <Route path="/offer" element={<Offer />} />
                          <Route path="/offer/increase-limit" element={<OfferIncLimit />} />

                          <Route path="/not-eligible" element={<NotEligible />} />
                          <Route path="/kyc" element={<KYC />} />
                          <Route path="/payment" element={<Payment />} />
                          <Route path="/e-sign" element={<Esign />} />
                          <Route path="/bank-account" element={<Emandate />} />

                          <Route path="/e-mandate" element={<EmandateList />} />
                          <Route path="/e-mandate-success" element={<EmandateSuccess />} />
                          <Route path="/lead-success" element={<LeadSuccess />} />

                          <Route path="/physical-nach-upload" element={<EmandateUpload />} />
                          <Route path="/physical-nach-success" element={<EmandateUploadSuccess />} />

                          <Route path="/success" element={<FinalSuccess />} />

                          <Route path="/increase-limit" element={<IncreaseLimit />} />
                          <Route path="/increase-limit/bank-account" element={<IncreaseLimitBank />} />
                          <Route path="/increase-limit/account-aggregator" element={<AccountA />} />
                          <Route path="/increase-limit/netbanking" element={<Netbanking />} />
                          <Route path="/increase-limit/bank-statement" element={<BankStatement />} />
                          <Route path="/increase-limit/gst" element={<GST />} />
                          <Route path="/increase-limit/itr" element={<ITR />} />

                          <Route path="/increase-limit/e-mandate" element={<IncreaseLimitEmandate />} />
                          <Route path="/increase-limit/e-sign" element={<IncreaseLimitEsign />} />
                          <Route path="/increase-limit/document" element={<Document />} />

                          <Route path="/esign_popup" name="Esign Page" element={<EsignPopup />} />                
                          <Route path="/esign_request" name="Esign" element={<EsignRequest />}/>

                          <Route path="/esign_callback" name="Esign Page" element={<EsignCallback />} />
                          <Route path="/emandate_callback" name="Emandate Page" element={<EmandateCallback />} />

                        </Routes>
                      </Suspense>
                   </Router>
                  </div>
              </Col>
          </Row>

        </Col>
      </Row>

    </div>
  );
}

export default App;
