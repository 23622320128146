const LOAD_DOCUMENT = 'Mintifi/App/Document/LOAD_DOCUMENT';
const SAVE_DOCUMENT = 'Mintifi/App/Document/SAVE_DOCUMENT';
const UPLOAD_DOCUMENT = 'Mintifi/App/Document/UPLOAD_DOCUMENT';
const STOP_LOADER = 'Mintifi/App/Document/STOP_LOADER';

export {
  LOAD_DOCUMENT,
  SAVE_DOCUMENT,
  UPLOAD_DOCUMENT,
  STOP_LOADER
};
