import Axios from '../../configurations/axios';
import {
  SEND_OTP_URL,
  VERIFY_OTP_URL,
  ANCHOR_SESSIONS_URL,
  OPS_BORROWER_URL,
  OPS_TOKEN_URL,
  ANCHOR_DISTRIBUTOR_URL,
} from '../../configurations/urls';
import config from '../../configurations/index';
import { store } from '../../configureStore';

const getAnchorKey = () => {
  return store.getState().PersonalReducer.anchorKey;
}

const getClientToken = () => {
  return getAnchorKey() || config.CLIENT_API_KEY;
}

const sendOTPService = async (data) => {
  const response = await Axios({
    url: SEND_OTP_URL,
    method: 'POST',
    headers: {
      'api-key': getClientToken(),
    },
    data:{
      "communication": {
              "recipient": data.mobile_number,
              "otp_type": "auth",
              "channel": "Mobile"
          }
      }
  });
  return response.data;
};

const anchorSessionService = async () => {
  const response = await Axios({
    url: `${ANCHOR_SESSIONS_URL}`,
    method: 'POST',
    headers: {
      'Accept': 'application/vnd.anchor_dashboard.com; version=1',
    },
    data: {
      "user": {
        "email": config.ANCHOR_MAIL,
        "password": config.ANCHOR_PASSWORD,
      }
    }
  });
  return response.data;
};

const opsTokenService = async (data) => {
  const response = await Axios({
    url: OPS_TOKEN_URL,
    method: 'POST',
    headers: {
      'token': data
    },
    data: {
      "user_id": config.OPS_USER_ID,
      "secret_key": config.OPS_SECRET_KEY,
      "type": "client"
    }
  });
  return response.data;
};

const distributorUserService = async (token, data) => {
  const response = await Axios({
    url: `${ANCHOR_DISTRIBUTOR_URL}`,
    method: 'POST',
    headers: {
      'accept': 'application/vnd.anchor_dashboard.com; version=1',
      'token': token,
      'Content-Type': 'application/json'
    },
    data: {
      "data": {
        "type": "user",
        "attributes": {
          "mobile_number": data,
          "language": "null"
        }
      }
    }
  });
  return response.data;
};

const loanAccountDetailService = async (token, data) => {
  const response = await Axios({
    url: `${OPS_BORROWER_URL}/${data}/loan_accounts?filter[loan_status][]=disbursed&filter[post_disbursed_status][]=active`,
    method: 'GET',
    headers: {
      'token': token,
    },
  });
  return response.data;
};

const verifyOTPService = async (data) => {
  const response = await Axios({
    url: VERIFY_OTP_URL,
    method: 'POST',
    headers: {
      'api-key': getClientToken(),
    },
    data:{
          "communication": {
              "recipient": data.mobile_number,
              "otp_reference_id": data.otpResponse.otp_reference_id,
              "otp": data.otp
          }
      }
  });
  return response.data;
};

export { 
  sendOTPService,
  verifyOTPService,
  anchorSessionService,
  distributorUserService,
  loanAccountDetailService,
  opsTokenService
};
