import React from 'react';
import Layout from './containers/Layout';
import configuration from './configurations';
import { notification,Spin,Row,Col } from 'antd';
import { Mixpanel } from './configurations/mixpanel';
import { loadLOS,loadBNPL } from './app_actions';
import { saveAnchorKeyAction,setCustomerCode,getDecryptedData,setAction,setTarget } from './Components/PersonalScreen/actions';
import { useInjectSaga } from './injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import saga from './saga';

import './App.css';

if (configuration.MIX_PANEL_TOKEN) {
    Mixpanel.init(configuration.MIX_PANEL_TOKEN);
    //Mixpanel.reset();
} 

function App({defaultdata}) {
  useInjectSaga({ key: 'appSaga', saga });
  const effectCalled = React.useRef(true);
  const { myToasst,appLoader } = useSelector(state => state.app ?? []);
  
   const dispatch = useDispatch();

  React.useEffect(() => {
    if(effectCalled.current) {
      effectCalled.current = false;
      let pathIgnore = ["/esign_popup","/esign_request","/esign_callback","/emandate_callback"]
      
      let isFlag = pathIgnore.indexOf(window.location.pathname) !== -1 ? true : false;

      if(!isFlag && localStorage.getItem('los_auth_token') && localStorage.getItem('los_anchor_id') && localStorage.getItem('los_company_id')) {

        dispatch(loadLOS(localStorage.getItem('los_auth_token'),localStorage.getItem('los_anchor_id'),localStorage.getItem('los_company_id')))

      }

      if(!isFlag && localStorage.getItem('bnpl_auth_token') && localStorage.getItem('bnpl_anchor_key')) {
          dispatch(loadBNPL(localStorage.getItem('bnpl_auth_token'),localStorage.getItem('bnpl_anchor_key')))
      }

    }

  },[])

  React.useEffect(() => {
    console.log(defaultdata);
    if(defaultdata.apiKey) {
      dispatch(saveAnchorKeyAction(defaultdata.apiKey));
    }

    if(defaultdata.customerCode) {
      dispatch(setCustomerCode(defaultdata.customerCode));
    }

    if(defaultdata) {
      Mixpanel.track('CUSTOMER_DATA_APP_SCREEN', {
        defaultdata: defaultdata
      });
    }
  },[defaultdata])


  React.useEffect(() => {
      if(myToasst) {
        var errorCheck = myToasst.status === "error" ? "info" : myToasst.status;
        notification[errorCheck]({
            message: 'Mintifi',
            description: myToasst.message,
        });
      }     
  },[myToasst])

  return (
    <div className="App">
      {appLoader &&
        <Row justify="center" style={{ marginTop: '150px' }}>
            <Col>
              <Spin size="large" />
            </Col>
          </Row>
      } 
      {!appLoader &&
        <Layout defaultdata={defaultdata}/>
      }     
    </div>
  );
}

export default App;