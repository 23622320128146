import produce from 'immer';
import { 
    ADD_PERSONAL_DETAILS,
    SAVE_PERSONAL_DETAILS,
    SAVE_AUTH_PERSONAL_DETAILS,
    STOP_LOADER,
    FETCH_PAN_DETAILS,
    SAVE_PAN_DETAILS,
    CREATE_BORROWER,
    SAVE_BORROWER,
    UPDATE_BORROWER,
    SAVE_UPDATE_BORROWER,
    LOAD_AUTH_TOKEN,
    SAVE_AUTH_TOKEN,
    FETCH_PIN_CODE,
    SAVE_PIN_CODE_DETAILS,
    GET_PAN_ANCHOR,
    SAVE_PAN_ANCHOR,
    GET_ANCHOR_KEY,
    SAVE_ANCHOR_KEY,
    SKIP_LOS,
    VALIDATE_LOS_APP,
    SAVE_OFFER,
    RELOAD_BNPL,
    UPDATE_BORROWER_AUTH,
    CHECK_BORROWER_AUTH,
    SAVE_CUSTOMER_CODE,
    SAVE_ACTION,
    SAVE_TARGET,
    SAVE_USER_DATA
} from './constants';

export const initialState = {
  panLoader: false,
  persoanalDetails: null,
  panDetails: null,
  createBrowwerLoader: false,
  browwerDetails: null,
  loaderDirector:false,
  partners:[],
  authToken: null,
  activeList: null,
  personalLoader: false,
  loginType: null,
  coborrowerAdded:false,
  tokenLoader: false,
  pinLoader:false,
  pinCodeDetail: null,
  panAnchorLoader:false,
  panAnchor: null,
  panAnchorPan: null,
  anchorKey:null,
  customerCode:null,
  skipLOS: false,
  anchorName: null,
  panBorrowerAuth:null,
  customerData:null,
  action:null,
  target:null,
  customerDataLoader:false,
  decrypt_data:null,
  callbackURL: null,
  userData: null
};

/* eslint-disable default-case, no-param-reassign */
const personalReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ADD_PERSONAL_DETAILS: {
        draft.personalLoader = true;
        break;
      } 
      case RELOAD_BNPL: {
        draft.anchorKey = action.payload.anchor_key;
        draft.browwerDetails = action.payload.borowwer;
        draft.activeList = action.payload.actlist;
        draft.authToken = action.payload.token;
        break;
      }
      case SAVE_PERSONAL_DETAILS: {
        draft.persoanalDetails = action.payload.data;
        draft.personalLoader = false;
        draft.loginType = "a";
        break;
      } 
      case LOAD_AUTH_TOKEN: {
        draft.tokenLoader = true;
        break;
      }
      case SAVE_AUTH_TOKEN: {
        draft.authToken = action.payload.auth;
        draft.tokenLoader = false;
        break;
      }
      case GET_PAN_ANCHOR: {
        draft.panAnchorLoader = true;
        break;
      }
      case SAVE_PAN_ANCHOR: {
        draft.panAnchor = action.payload.response;
        draft.panAnchorPan = action.payload.pan;
        draft.panAnchorLoader = false;
        break;
      }
      case GET_ANCHOR_KEY: {
        draft.personalLoader = true;
        break;
      }
      case SAVE_ANCHOR_KEY: {
        draft.anchorKey = action.payload.auth;
        draft.personalLoader = false;
        break;
      }
      case SAVE_CUSTOMER_CODE: {
        draft.customerCode = action.payload.auth;
        break;
      }
      case SAVE_AUTH_PERSONAL_DETAILS: {
        draft.persoanalDetails = action.payload.data;
        draft.authToken = action.payload.auth;
        draft.browwerDetails = action.payload.browwerDetail;
        if(action.payload.activeList) {
          draft.loginType = "b";
          draft.activeList = action.payload.activeList;
        }
        else {
          draft.loginType = "c";
        }        
        draft.personalLoader = false;
        
        break;
      }   
      case FETCH_PAN_DETAILS: {
        draft.panLoader = true;
        break;
      }
      case FETCH_PIN_CODE: {
        draft.pinLoader = true;
        break;
      }
      case CREATE_BORROWER: {
        draft.createBrowwerLoader = true;
        break;
      }  
      case SAVE_BORROWER: {
        draft.createBrowwerLoader = false;
        draft.browwerDetails = action.payload.response;
        break;
      } 
      case UPDATE_BORROWER: {
        draft.loaderDirector = true;
        break;
      }
      case SAVE_UPDATE_BORROWER: {
        draft.loaderDirector = false;
        draft.coborrowerAdded = true;
        draft.browwerDetails = action.payload.data;
        break;
      }
      case SAVE_PAN_DETAILS: {
        draft.panLoader = false;
        let panDAta = !draft.panDetails ? {} :  draft.panDetails;
        panDAta[action.payload.pan_number] = action.payload.response;
        draft.panDetails = panDAta;
        break;
      }
      case SAVE_PIN_CODE_DETAILS: {
        draft.pinLoader = false;
        let temp_pindata = !draft.pinCodeDetail ? {} : draft.pinCodeDetail;
        temp_pindata[action.payload.pin_code] = action.payload.response;
        draft.pinCodeDetail = temp_pindata;
        break;
      }
      case SKIP_LOS: {
        draft.skipLOS = true;
        break;
      }
      case VALIDATE_LOS_APP: {
        draft.personalLoader = true;
        draft.anchorName = action.payload.name
        break;
      }
      case SAVE_OFFER: {
        draft.browwerDetails = action.payload.response;
        break;
      }      
      case STOP_LOADER: {
        draft[action.payload.loaderName] = false;
        break;
      }
      case UPDATE_BORROWER_AUTH: {
        draft.panBorrowerAuth = action.payload.data;
        draft.personalLoader = false;
        break;
      }
      case CHECK_BORROWER_AUTH: {
        draft.personalLoader = true;
        break;
      }
      case SAVE_ACTION: {
        draft.action = action.payload.action;
        break;
      }
      case SAVE_TARGET: {
        draft.target = action.payload.target;
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default personalReducer;
