import axios from 'axios';
import config from './index';
import { store } from '../configureStore';
//import { logoutUser } from '../App/actions';

const { API_TIMEOUT } = config;

const { dispatch } = store;

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: API_TIMEOUT * 1000,
});

instance.interceptors.request.use(axiosConfig => {
  if(axiosConfig.url && axiosConfig.url.indexOf(config.URL.ONBOARDING_API_SERVICE) !== -1 && store.getState() && store.getState().PersonalReducer && store.getState().PersonalReducer.customerCode) {    
        let newURL = new URL(axiosConfig.url);
        const urlParams1 = new URLSearchParams(newURL.search);
        urlParams1.set('customer_code', store.getState().PersonalReducer.customerCode);
        axiosConfig.url = newURL.protocol+"//"+newURL.host+newURL.pathname +"?"+ urlParams1.toString();
  }
  return {
    ...axiosConfig,
    headers: { ...axiosConfig.headers },
  };
});

instance.interceptors.response.use(
  response => response,
  ({ message, response }) => {
    console.log("Api Error",message, response);

    if (message.includes("timeout") || message.includes("Network Error")) {
      const toast = {status: "error",message: "hello"};

      if(message.includes("timeout")) {
        toast.message = "Looks like the server is taking to long to respond, please try again in sometime.";
      }

       if(message.includes("Network Error")) {
        toast.message = "Whoops! Sorry - something didn't go through! Please check your network connection, then try again.";
      }

      dispatch({type: 'Mintifi/App/SET_TOAST',payload: {toast}})
      return Promise.reject({ message, data, status });
    }

    const { data, status } = response;
    console.log("Api Response Error",data,status,message,response);
    
    if(data && data.errors && data.errors[0] && data.errors[0].code && data.errors[0].detail && data.errors[0].code === "ER-AUTH-103" && data.errors[0].detail === "Authorization Failure"){
      localStorage.removeItem('bnpl_auth_token');
      localStorage.removeItem('bnpl_anchor_key');

      localStorage.removeItem('los_auth_token');
      localStorage.removeItem('los_anchor_id');
      localStorage.removeItem('los_company_id');

      window.location.assign("/")
    }
    if(data && data.errors && data.errors[0] && data.errors[0].code && data.errors[0].detail && data.errors[0].code === "ER-AUTH-102" && data.errors[0].detail ===  "Invalid Auth Token"){
      localStorage.removeItem('bnpl_auth_token');
      localStorage.removeItem('bnpl_anchor_key');

      localStorage.removeItem('los_auth_token');
      localStorage.removeItem('los_anchor_id');
      localStorage.removeItem('los_company_id');

      window.location.assign("/")
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ message, data, status });
  },
);

export default instance;
