import configuration from './index';

const mixpanel = require('mixpanel-browser');

const mixpanelActions = {
  // To initialize the mix-panel track
  init: id => {
    mixpanel.init(id);
  },
  // To identify each track belongs to which user
  identify: id => {
    mixpanel.identify(id);
  },
  // To track the actions / events belongs to different users
  track: (name, ...props) => {
    mixpanel.track(name, ...props);
  },
  // resetting mix-panel config while logging out
  reset: () => mixpanel.reset(),
  // can get the UNIQUE ID that has been assigned to the individual user
  getDistinctID: () => mixpanel.get_distinct_id(),
};

const mockMixpanelActions = {
  init: () => undefined,
  // To identify each track belongs to which user
  identify: () => undefined,
  // To track the actions / events belongs to different users
  track: () => undefined,
  // resetting mix-panel config while logging out
  reset: () => undefined,
  // can get the UNIQUE ID that has been assigned to the individual user
  getDistinctID: () => undefined,
};

const Mixpanel = configuration.MIX_PANEL_TOKEN
  ? mixpanelActions
  : mockMixpanelActions;


const trackStrings = {};

export { Mixpanel, trackStrings };
