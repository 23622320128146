import produce from 'immer';
import { STOP_LOADER,FETCH_OFFER,APPLY_OFFER,SAVE_APPLY_OFFER,SAVE_OFFER_ERROR,INC_OFFER,FETCH_BROWWER,SAVE_BROWWER } from './constants';

export const initialState = {
  offerLoader:false,
  applyOfferLoader: false,
  saveOffer: false,
  offerError: false,
  incLmtbrowwerDetails: null
};

/* eslint-disable default-case, no-param-reassign */
const mobileReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case STOP_LOADER: {
        draft[action.payload.loaderName] = false;
        break;
      }
      case APPLY_OFFER: {
        draft.applyOfferLoader = true;
        break;
      }
      case FETCH_BROWWER: {
        draft.applyOfferLoader = true;
        break;
      }     
      case SAVE_BROWWER: {
        draft.incLmtbrowwerDetails = action.payload.response;
        break;
      }
      case SAVE_APPLY_OFFER: {
        draft.saveOffer = true;
        break;
      }
      case FETCH_OFFER: {
        draft.offerLoader = true;
        break;
      }
      case SAVE_OFFER_ERROR: {
        draft.offerError = action.payload.response;
        break;
      }
      case INC_OFFER: {
        draft.applyOfferLoader = true;
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default mobileReducer;