const IFSC_API = 'Mintifi/App/emandate/IFSC_API';
const SAVE_IFSC_API = 'Mintifi/App/emandate/SAVE_IFSC_API';
const PENNY_DROP_CALL = 'Mintifi/App/emandate/PENNY_DROP_CALL';
const SAVE_PENNY_DROP_CALL = 'Mintifi/App/emandate/SAVE_PENNY_DROP_CALL';
const STOP_LOADER = 'Mintifi/App/emandate/STOP_LOADER';
const SAVE_BANK = 'Mintifi/App/emandate/SAVE_BANK';
const SAVE_BANK_DATA = 'Mintifi/App/emandate/SAVE_BANK_DATA';
const START_LOADER = 'Mintifi/App/emandate/START_LOADER';
const GET_BANK_LIST = 'Mintifi/App/emandate/GET_BANK_LIST';
const INIT_EMANDATE = 'Mintifi/App/emandate/INIT_EMANDATE';
const SET_API_STEP = 'Mintifi/App/emandate/SET_API_STEP';
const PHY_NACH = 'Mintifi/App/emandate/PHY_NACH';
const SAVE_PHY_NACH = 'Mintifi/App/emandate/SAVE_PHY_NACH';
const SAVE_INIT_EMANDATE = 'Mintifi/App/emandate/SAVE_INIT_EMANDATE';
const GET_BANK_DATA = 'Mintifi/App/emandate/GET_BANK_DATA';
const SAVE_SET_API_STEP = 'Mintifi/App/emandate/SAVE_SET_API_STEP';
const SAVE_BORROWER_ACTION = 'Mintifi/App/emandate/SAVE_BORROWER_ACTION';
const SAVE_ENCRYPTED_DATA = 'Mintifi/App/emandate/SAVE_ENCRYPTED_DATA';

export {
    IFSC_API,
    SAVE_IFSC_API,
    PENNY_DROP_CALL,
    SAVE_PENNY_DROP_CALL,
    STOP_LOADER,
    SAVE_BANK,
    SAVE_BANK_DATA,
    START_LOADER,
    GET_BANK_LIST,
    INIT_EMANDATE,
    SAVE_INIT_EMANDATE,
    SET_API_STEP,
    PHY_NACH,
    SAVE_PHY_NACH,
    GET_BANK_DATA,
    SAVE_SET_API_STEP,
    SAVE_BORROWER_ACTION,
    SAVE_ENCRYPTED_DATA
}