import Axios from '../../configurations/axios';
import {
  LOS_AA_URL,
  CREDIT_NETBANKING_URL,
  CREDIT_BS_URL,
  CREDIT_ITR_URL,
  CREDIT_GET_GST_URL,
  CREDIT_OTP_GST_URL,
  CREDIT_SUBMIT_GST_URL,
  OPS_TOKEN_URL,
  OPS_CREATE_LOAN_URL,
  PAN_LOAN_URL,
  COMPANY_LOAN_URL,
  LOS_STATUS_URL,
  LOS_EMANDATE_URL,
  LOS_ESIGN_URL,
  LOS_DOCUMENT_URL
} from '../../configurations/urls';
import { store } from '../../configureStore';
import config from '../../configurations/index';

const getLOSToken = () => {
  return store.getState().IncLimitReducer.losToken;
}

const getAuthToken = () => {
  return store.getState().PersonalReducer.authToken;
}

const getAnchorKey = () => {
  return store.getState().PersonalReducer.anchorKey;
}

const accountAggrService = async (data) => {
  const response = await Axios({
    url: LOS_AA_URL,
    method: 'POST',
    headers: {
      'token': getLOSToken(),
      'app-id': '1'
    },
    data: data
  });
  return response.data;
};

const nebankingService = async (data) => {
  const response = await Axios({
    url: CREDIT_NETBANKING_URL,
    method: 'POST',
    headers: {
      'token': getLOSToken(),
    },
    data: data
  });
  return response.data;
};

const uploadBSService = async (data) => {
  const response = await Axios({
    url: CREDIT_BS_URL,
    method: 'POST',
    headers: {
      'token': getLOSToken(),
    },
    data: data
  });
  return response.data;
};

const submitITRService = async (data) => {
  const response = await Axios({
    url: CREDIT_ITR_URL,
    method: 'POST',
    headers: {
      'token': getLOSToken(),
    },
    data: data
  });
  return response.data;
};

const getGSTService = async (data) => {
  const response = await Axios({
    url: CREDIT_GET_GST_URL,
    method: 'POST',
    headers: {
      'token': getLOSToken(),
    },
    data: data
  });
  return response.data;
};

const otpGSTService = async (data) => {
  const response = await Axios({
    url: CREDIT_OTP_GST_URL,
    method: 'POST',
    headers: {
      'token': getLOSToken(),
    },
    data: data
  });
  return response.data;
};


const submitGSTService = async (data) => {
  const response = await Axios({
    url: CREDIT_SUBMIT_GST_URL,
    method: 'POST',
    headers: {
      'token': getLOSToken(),
    },
    data: data
  });
  return response.data;
};

const opsTokenService = async (data) => {
  const response = await Axios({
    url: OPS_TOKEN_URL,
    method: 'POST',
    data: {
      "user_id": config.OPS_USER_ID,
      "secret_key": config.OPS_SECRET_KEY,
      "type": "client"
    }
  });
  return response.data;
};

const validateLOSAppService = async (pan,anchor_id,token) => {
  const response = await Axios({
    url: PAN_LOAN_URL+anchor_id+"/loan_application?pan_number="+pan.toUpperCase()+"&source=onboarding",
    method: 'GET',
    headers: {
      'token': token,
    },
  });
  return response.data;
};

const validateLOSAppCompanyService = async (company_id,anchor_id,token) => {
  const response = await Axios({
    url: COMPANY_LOAN_URL+company_id+"/loan_application/latest?anchor_id="+anchor_id+"&source=onboarding",
    method: 'GET',
    headers: {
      'token': token || getLOSToken(),
    },
  });
  return response.data;
};

const fetchLoanStatusService = async (id,anchor_id) => {
  const response = await Axios({
    url: LOS_STATUS_URL+id+"/status.json",
    method: 'GET',
    headers: {
      'token': getLOSToken(),
    },
    data: {
        anchor_id: anchor_id,
        app_id: 1
    }
  });
  return response.data;
};

const opsCreateLoanService = async (token,loanRequest) => {
  const response = await Axios({
    url: OPS_CREATE_LOAN_URL,
    method: 'POST',
    headers: {
      'token': token,
      'id': 2000,
      'type': 'anchor'
    },
    data: loanRequest
  });
  return response.data;
};

const emandateService = async (request) => {
  const response = await Axios({
    url: LOS_EMANDATE_URL,
    method: 'POST',
    headers: {
      'token': getLOSToken(),
      'app-id': '1'
    },
    data: request
  });
  return response.data;
};

const esignService = async (request) => {
  const response = await Axios({
    url: LOS_ESIGN_URL,
    method: 'POST',
    headers: {
      'token': getLOSToken(),
      'app-id': '1'
    },
    data: request
  });
  return response.data;
};

const uploadDocumentService = async (data) => {
  const response = await Axios({
    url: LOS_DOCUMENT_URL,
    method: 'POST',
    headers: {
      // 'api-key': getAnchorKey(),
      'token': getLOSToken()
    },
    data: data
  });
  return response.data;
};
export { 
  accountAggrService,
  nebankingService,
  uploadBSService,
  submitITRService,
  getGSTService,
  otpGSTService,
  submitGSTService,
  opsTokenService,
  opsCreateLoanService,
  validateLOSAppService,
  validateLOSAppCompanyService,
  fetchLoanStatusService,
  emandateService,
  esignService,
  uploadDocumentService
};
