import produce from 'immer';
import { SEND_OTP,STOP_LOADER,SAVE_OTP,VERIFY_OTP,VERIFY_OTP_RESPONSE,CLEAR_OTP,DISTRIBUTOR_DETAILS,DISTRIBUTOR_LOADER } from './constants';

export const initialState = {
  otpLoader: false,
  otpResponse: null,
  userResponse: null,
  distributorStatus: false,
  distributorLoader:false,
};

/* eslint-disable default-case, no-param-reassign */
const mobileReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SEND_OTP: {
        draft.otpLoader = true;
        break;
      }
      case VERIFY_OTP: {
        draft.otpLoader = true;
        break;
      }
      case SAVE_OTP: {
        draft.otpLoader =  false;  
        draft.otpResponse = action.payload.response;
        break;
      }
      case VERIFY_OTP_RESPONSE: {
        draft.otpLoader =  false;  
        draft.userResponse = action.payload.response;
        break;
      }      
      case CLEAR_OTP: {
        draft.userResponse = null;
      }
      case STOP_LOADER: {
        draft[action.payload.loaderName] = false;
        break;
      }
      case DISTRIBUTOR_DETAILS: {
        draft.distributorStatus = true;
        break;
      }
      case DISTRIBUTOR_LOADER: {
        draft.distributorLoader = action.payload.data;
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default mobileReducer;