import React, { useEffect } from 'react';
import { Col, Row, Typography, Input, Button, Select, DatePicker, Form, Checkbox } from 'antd';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createBorrowerAction,fetchPinCodeAction } from "./actions";
import { stepAction } from '../../app_actions';
import { Mixpanel } from '../../configurations/mixpanel';
const { Paragraph } = Typography;
const { Option } = Select;
const moment = require('moment')

function AddGST() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const formRef = React.createRef();
  const [pattern,setPattern] =  React.useState(/^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/);

  const { persoanalDetails, browwerDetails,pinCodeDetail,createBrowwerLoader,customerCode,action } = useSelector(state => state.PersonalReducer ?? []);
  const { userResponse } = useSelector(state => state.mobileReducer ?? []);
  const { userData, decrypt_data } = useSelector(state => state.app ?? []);

  const handleAddGstForm = (values) => {

    let request = {
      "borrower": {
        "name": persoanalDetails.username,
        "email": persoanalDetails.email,
        "pan_number": persoanalDetails.pan,
        "mobile_number": userResponse.recipient,
        "gst_number": values.gst_number,
        "source": 'onboarding',
        "bureau_consent": moment().format('DD/MM/YYYY HH:mm:ss'),
        "borrower_detail_attributes": {
          // "firm_type": gstOBJSel.constitution_of_business,
          "date_of_incorporation": moment(values.date_of_inc).format('YYYY-MM-DD'),
          "number_of_directors": 1
        },
        "customer_codes_attributes": [
          {
            "code": customerCode || persoanalDetails.pan
          }
        ],
        "addresses_attributes": [
          {
            "pincode": values.company_pincode,
            "city": values.company_city,
            "state": values.company_state,
            "address_type": "Business",
            "ownership_type": values.property_ownership,
            "address_line1": values.company_address,
            "is_primary": true
          }
        ]
      }
    };
    dispatch(createBorrowerAction(request))
  }

  React.useEffect(()=>{
    window.scrollTo(0, 0);
    dispatch(stepAction(0));
    Mixpanel.track('ADD_GST_SCREEN_LOADED');
  },[]);
  
  const pinCodeOnChange = (code) => {
    if(code && code.length === 6){
      formRef.current.setFieldsValue({
        company_city: "",
        company_state:""
      });
      var data = {
        pin_code:code
      }
      dispatch(fetchPinCodeAction(data))
    }else{
      formRef.current.setFieldsValue({
        company_city: "",
        company_state:""
      });
    }  
  }
  React.useEffect(() => {
    if (browwerDetails && browwerDetails.id) {
      history('/co-borrower');
    }
  }, [browwerDetails]); 

  React.useEffect(()=>{
    if(userResponse === null){
    localStorage.removeItem('los_auth_token');
    localStorage.removeItem('los_anchor_id');
    localStorage.removeItem('los_company_id');
    localStorage.removeItem('bnpl_auth_token');
    localStorage.removeItem('bnpl_anchor_key');
      window.open('/','_self');
    }
  },[userResponse]);

  useEffect(()=>{
    if(pinCodeDetail && formRef.current && formRef.current.getFieldsValue().company_pincode){
      formRef.current.setFieldsValue({
        company_city: pinCodeDetail[formRef.current.getFieldsValue().company_pincode].city,
        company_state:pinCodeDetail[formRef.current.getFieldsValue().company_pincode].state
      });
    }
    
  },[pinCodeDetail]);

  useEffect(()=>{
    console.log("est")

    if(action === "onboarding" && decrypt_data && decrypt_data.borrower){
      
      let prefillRequest = {};
      
      if(decrypt_data.borrower.gst_number && pattern.test(decrypt_data.borrower.gst_number)) {
        prefillRequest.gst_number = decrypt_data.borrower.gst_number;
      }

      if(decrypt_data.borrower.borrower_detail_attributes && decrypt_data.borrower.borrower_detail_attributes.date_of_incorporation) {
         prefillRequest.date_of_inc = moment(decrypt_data.borrower.borrower_detail_attributes.date_of_incorporation,'DD/MM/YYYY');
      }

      var foundValue = decrypt_data.borrower.addresses_attributes.find( obj => obj.address_type === 'Business');
      console.log("foundValue",foundValue);
      if(foundValue){
        var pin = /[0-9]/.test(foundValue.pincode);
        
        if(pin){
          pinCodeOnChange(foundValue.pincode)
        }

        prefillRequest.company_address = foundValue.address_line1 + foundValue.address_line2 || "";
        prefillRequest.company_pincode = pin && foundValue.pincode || "";
        prefillRequest.property_ownership = foundValue.ownership_type || "";
      }

      if(Object.keys(prefillRequest).length) {
        formRef.current.setFieldsValue(prefillRequest)
      }

    }
    
  },[]);

  return (
    <>
      <Typography.Title className="mnt-color-blue1" level={4}>Step 1 of 3!</Typography.Title>
      <Paragraph className="mnt-color-blue2">Please add your Company & GST details.</Paragraph>
      <Form onFinish={handleAddGstForm} ref={formRef} initialValues={{company_name: persoanalDetails && persoanalDetails.username, company_email: persoanalDetails && persoanalDetails.email }}>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 18 }} className="mnt-input-cnt">
            <Typography.Text type="secondary" style={{ fontSize: '11px' }}>GST Number</Typography.Text>
            <Form.Item style={{ margin: "0" }} name="gst_number" rules={[
              {
                required: true,
                message: "This field is required"
              },
              {
                pattern: /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
                message: 'Please enter valid GST Number.',
              },
            ]}>
              <Input size="small" className="mnt-input" placeholder="" disabled={decrypt_data && decrypt_data.borrower && decrypt_data.borrower.gst_number && pattern.test(decrypt_data.borrower.gst_number)}/>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 18 }} className="mnt-input-cnt">
            <Typography.Text type="secondary" style={{ fontSize: '11px' }}>Date of INC</Typography.Text>
            <br />
            <Form.Item style={{ margin: "0" }} name="date_of_inc" rules={[
              {
                required: true,
                message: "This field is required"
              }
            ]}>
              <DatePicker
                style={{ borderBottom: "2px solid #03064D", borderLeft: "none", borderTop: "none", borderRight: "none" }}
                className="mnt-input"
                disabledDate={(currentDate) => currentDate > moment().startOf('day')}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 18 }} className="mnt-input-cnt">
            <Typography.Text type="secondary" style={{ fontSize: '11px' }}>Address</Typography.Text>
            <Form.Item style={{ margin: "0" }} name="company_address" rules={[
              {
                required: true,
                message: "This field is required"
              },
              {
                min: 10,
                message: "Address cannot be less than 10 characters"
              }
            ]}>
              <Input size="small" className="mnt-input" placeholder="" />
            </Form.Item>
          </Col>

           <Col xs={{ span: 24 }} lg={{ span: 18 }} className="mnt-input-cnt">
            <Typography.Text type="secondary" style={{ fontSize: '11px' }}>Pincode</Typography.Text>
            <Form.Item style={{ margin: "0" }} name="company_pincode" rules={[
              {
                required: true,
                message: "This field is required"
              },
              {
                // pattern: /^[1-9][0-9]{5}$/,
                min: 6,
                message: 'Please enter a valid 6-digit pincode.',
              }
            ]}>
              <Input size="small" className="mnt-input" 
              placeholder=""
              maxLength={6}
              onChange={(e)=>pinCodeOnChange(e.target.value)}
                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 18 }} className="mnt-input-cnt">
            <Typography.Text type="secondary" style={{ fontSize: '11px' }}>City</Typography.Text>
            <Form.Item style={{ margin: "0" }} name="company_city" rules={[
              {
                required: true,
                message: "This field is required"
              }
            ]}>
              <Input size="small" className="mnt-input" placeholder="" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 18 }} className="mnt-input-cnt">
            <Typography.Text type="secondary" style={{ fontSize: '11px' }}>State</Typography.Text>
            <Form.Item style={{ margin: "0" }} name="company_state" rules={[
              {
                required: true,
                message: "This field is required"
              }
            ]}>
              <Input size="small" className="mnt-input" placeholder="" />
            </Form.Item>
          </Col>
          
          <Col xs={{ span: 24 }} lg={{ span: 18 }} className="mnt-input-cnt">
            <Typography.Text type="secondary" style={{ fontSize: '11px' }}>Property Ownership</Typography.Text>
            <br />
            <Form.Item style={{ margin: "0" }} name="property_ownership" rules={[
              {
                required: true,
                message: "This field is required"
              }
            ]}>
              <Select
                defaultValue=""
                style={{ borderBottom: "2px solid #03064D" }}
                // onChange={handleChange}
                className="mnt-input"
                bordered={false}
              >
                <Option value="Owned">Owned</Option>
                <Option value="Rented">Rented</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 14 }} className="mnt-btn-cnt" >
            <Form.Item noStyle shouldUpdate>
                {({ getFieldsValue }) => {
                  const { gst_number, date_of_inc, company_pincode, company_address, company_city, company_state, property_ownership } = getFieldsValue();
                  const formIsComplete = !!gst_number && !!date_of_inc && !!company_pincode && !!company_address && !!company_city && !!company_state && !!property_ownership;
                  return (
                    <Button disabled={!formIsComplete} type={formIsComplete ? "primary" : "disabled"} size="large" htmlType='submit' className="mnt-btn" loading={createBrowwerLoader} >CONFIRM GST DETAILS</Button>
                  );
                }}
                </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default AddGST;
