const FETCH_OFFER = 'Mintifi/App/Offer/FETCH_OFFER';
const SAVE_OFFER = 'Mintifi/App/Offer/SAVE_OFFER';
const APPLY_OFFER = 'Mintifi/App/Offer/APPLY_OFFER';
const STOP_LOADER = 'Mintifi/App/Offer/STOP_LOADER';
const SAVE_APPLY_OFFER = 'Mintifi/App/Offer/SAVE_APPLY_OFFER';
const SAVE_OFFER_ERROR = 'Mintifi/App/Offer/SAVE_OFFER_ERROR';
const INC_OFFER = 'Mintifi/App/Offer/INC_OFFER';
const SKIP_LOS = 'Mintifi/App/Offer/SKIP_LOS';
const FETCH_BROWWER = 'Mintifi/App/Offer/FETCH_BROWWER';
const SAVE_BROWWER = 'Mintifi/App/Offer/SAVE_BROWWER';

export {
  FETCH_OFFER,
  STOP_LOADER,
  SAVE_OFFER,
  APPLY_OFFER,
  SAVE_APPLY_OFFER,
  INC_OFFER,
  SAVE_OFFER_ERROR,
  SKIP_LOS,
  FETCH_BROWWER,
  SAVE_BROWWER
};
