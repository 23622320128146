/* eslint-disable no-empty */
import { call, put, takeLatest } from 'redux-saga/effects';

import { stopLoader } from './app_actions';
import { setToastAction,saveUserData,saveDecryptedData,saveLeadData,saveLeadErrorData } from './app_actions';
import { losAppReloginAction } from './Components/IncreaseLimit/actions';
import { validateLOSAppCompanyService } from './Components/IncreaseLimit/services';
import { fetchBrowwerService,activeListService,decryptCustomerData, fetchMobileByPAN } from './Components/PersonalScreen/services';
import { savebnplAuthAction } from './Components/PersonalScreen/actions';

import { deserialize } from 'jsonapi-fractal'
import { Mixpanel } from './configurations/mixpanel';
import { LOAD_LOS,LOAD_BNPL,SAVE_CUSTOMER_DATA,CREATE_LEAD,FETCH_BORROWER_DETAILS } from './app_constants';
// import { SAVE_BORROWER_DETAILS } from './Components/PersonalScreen/constants';
import {getErrMessage} from './configurations/utils';
import { createLeadService } from './Components/E-mandate/services';
import { saveBorrowerAction } from './Components/PersonalScreen/actions';

function* loadLOSSaga(props) {
  try {
    const response = yield call(validateLOSAppCompanyService,props.payload.company_id,props.payload.anchor_id,props.payload.token);

    if(response && response.data) {
      var data = deserialize(response);
      Mixpanel.track('APP_SCREEN_FETCH_LOAN_SUCCESS', {
            request_data: props.payload.data,
            response: data
      });
      yield put(stopLoader('appLoader'))
      yield put(losAppReloginAction(props.payload.token,data));      
    }

  } catch(err) {
    Mixpanel.track('APP_SCREEN_FETCH_LOAN_ERROR', {
            request_data: props.payload.data,
            error: err
    });
    if(err && err.data){ 
      yield put(setToastAction({status: "error",message: getErrMessage(err.data)}))     
    }
    yield put(stopLoader('appLoader'))
  }
}


function* loadBNPLSaga(props) {
  try {
        const response = yield call(fetchBrowwerService,props.payload.token,props.payload.anchor_key);
        if(response && response.data) {
          var data = deserialize(response);
          Mixpanel.track('PERSONAL_SCREEN_BROWWER_DETAIL_SUCCESS', {
                request_data: props.payload.data,
                response: data
          });
          
          try {
                const response1 = yield call(activeListService,props.payload.token,props.payload.anchor_key);

                if(response1 && response1.data) {
                  var data1 = deserialize(response1);
                  Mixpanel.track('PERSONAL_SCREEN_ACTIVE_LIST_SUCCESS', {
                        request_data: props.payload.data,
                        response: data1
                  });
                  yield put(stopLoader('appLoader'))
                  yield put(savebnplAuthAction(data,data1,props.payload.token,props.payload.anchor_key));
                }

              } catch(err1) {

                Mixpanel.track('PERSONAL_SCREEN_ACTIVE_LIST_ERROR', {
                        request_data: props.payload.data,
                        error: err1
                });
                yield put(setToastAction({status: "error",message: getErrMessage(err1.data)}))
                yield put(stopLoader('appLoader'));
                yield put(savebnplAuthAction(data,null,props.payload.token,props.payload.anchor_key));
          }


        }

      } catch(errB) {
        Mixpanel.track('PERSONAL_SCREEN_BROWWER_DETAIL_ERROR', {
                request_data: props.payload.data,
                error: errB
        });
        if(errB && errB.data){ 
          yield put(setToastAction({status: "error",message: getErrMessage(errB.data)}))     
        }
        yield put(stopLoader('appLoader'))
      } 
}

function* createLeadSaga(props) {
  try {
    const response = yield call(createLeadService,props.payload);

    if(response && response.data) {
      var data = deserialize(response);
      yield put(stopLoader('leadLoader'))
      yield put(saveLeadData(data))
      Mixpanel.track('SALSFORCE_LEAD_CREATE_SUCCESS', {
            request_data: props.payload.key,
            response: data
      });
    }
  } catch(err) {
    if(err && err.data){ 
      Mixpanel.track('SALSFORCE_LEAD_CREATE_ERROR', {
            request_data: props.payload.key,
            error: err
      });
      yield put(saveLeadErrorData(err))
    }
    yield put(stopLoader('leadLoader'))
  }
}

function* fetchBorrowerDetailsSaga() {
  try {
    const response = yield call(fetchBrowwerService);
    if(response && response.data) {
      var data = deserialize(response);
      yield put(saveBorrowerAction(data));      
    }
    yield put(stopLoader('leadLoader'))

  } catch(err) {
    if(err && err.data){ 
      yield put(setToastAction({status: "error",message: getErrMessage(err.data)})) 
    }
    yield put(stopLoader('leadLoader'))
  }
}

function* setDecryptedDataAppSaga(props) {
  console.log("props",props)
  try {
    const response = yield call(decryptCustomerData,props.payload.cust_data);
    console.log("response",response)
    Mixpanel.track('DECRYPT_SUCCESS', {
             request_data: props.payload.cust_data,
             response: response
    });
    if(response && response.data) {
      var data = deserialize(response);
      yield put(saveDecryptedData(data));
    }
    try{
      if(response && response.data && response.data.attributes && response.data.attributes.pan_number){
        const response1 = yield call(fetchMobileByPAN,response.data.attributes.pan_number);
        if(response1 && response1.data) {
          var data = deserialize(response1);
          yield put(saveUserData(data));
        }
      }
    } catch(err) {
      // Mixpanel.track('APP_SCREEN_FETCH_LOAN_ERROR', {
      //         request_data: props.payload.data,
      //         error: err
      // });
      if(err && err.data){ 
        yield put(setToastAction({status: "error",message: getErrMessage(err.data)}))     
      }
      yield put(stopLoader('appLoader'))
    }
  } catch(err) {
      Mixpanel.track('DECRYPT_ERROR', {
             request_data: props.payload.cust_data,
             error: err
      });
    if(err && err.data){
      yield put(setToastAction({status: "error",message: getErrMessage(err.data)}))
    }
  }
}


export default function* appSaga() {
  yield takeLatest(LOAD_LOS, loadLOSSaga);
  yield takeLatest(LOAD_BNPL, loadBNPLSaga);
  yield takeLatest(SAVE_CUSTOMER_DATA, setDecryptedDataAppSaga);
  yield takeLatest(CREATE_LEAD, createLeadSaga);
  yield takeLatest(FETCH_BORROWER_DETAILS,fetchBorrowerDetailsSaga)
}
