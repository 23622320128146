import config from '../configurations/index'

const redirectMe = (active_list,anchor_id) => {

	let newActList = [];
	let flag = false;
	let currentStep = null;

	if(Number(config.FALLBACK_ANCHOR_ID) === Number(anchor_id)){
		return flag ? currentStep : '/lead-success';
	}
	
	let stepList = {
		  emandate_activated: "/success",
		  emandate_success: "/e-mandate-success",
		  physical_nach_upload: "/physical-nach-success",
		  physical_nach_download: "/physical-nach-upload",
		  esign: "/e-mandate",
		  bank_account: "/e-sign",
		  documentation: "/bank-account",
		  offer_accepted: "/kyc",
		  offer: "/offer",
		  co_borrower: "/offer",
		  borrower: "/co-borrower",
	};

  for(var xyz in active_list) {
    newActList.push(active_list[xyz].step);
  }

  for(var abc in stepList) {
    if(flag === false && newActList.indexOf(abc) !== -1) {
      currentStep = stepList[abc];
      flag = true;
    }
  }

  return flag ? currentStep : '/success';
}

function getErrMessage (errs1) {
	let data = "";
	if(errs1.error) {
	  data = errs1.error;
	}
	else {
	  var errs = errs1.errors;
	  for(var x in errs) {
		var pointerSplit = errs[x].source.pointer.split('/');
		var removeUnderScore = pointerSplit[pointerSplit.length-1].replace(/_/g, ' ');
		var cnnt = removeUnderScore +" "+ errs[x].detail;
  
		var newMsg = errs[x].custom_message ? errs[x].custom_message : cnnt[0].toUpperCase() + cnnt.slice(1)
  
		data += data === "" ? newMsg : ", "+newMsg; 
	  }
	}
	return data;
}

function getErrCODE(errs) {
	let data = [];
	for(var x in errs) {
	  data.push(errs[x].code) 
	}
	return data;
}

const retrieveParam = (urlToParse, key) => {
      // if (urlToParse.length > 0)
      try {
        let url = new URL(urlToParse);
        let paramVal = url.searchParams.get(key);

        return paramVal;
      } catch (e) {
        return null;
      }
};


export {
	redirectMe,
	getErrMessage,
	getErrCODE,
	retrieveParam
};