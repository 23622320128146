import produce from 'immer';
import { START_LOADER,STOP_LOADER,GENERATE_ESIGN,INT_ESIGN,SAVE_GENERATE_ESIGN,
  SAVE_INT_ESIGN,
  SAVE_EMAIL_ESIGN } from './constants';

export const initialState = {
  documentList: null,
  esignGenerateLoader:false,
  esignInitLoader:false,
  esignResponse:null,
  loaderEsignDocument:false,
  esignResponseEmail:null

};

/* eslint-disable default-case, no-param-reassign */
const esignReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case STOP_LOADER: {
        draft[action.payload.loaderName] = false;
        break;
      }
      case SAVE_GENERATE_ESIGN: {
        draft.esignGenerateLoader = false;
        draft.documentList = action.payload.response;
        break;
      }
      case GENERATE_ESIGN: {
        draft.esignGenerateLoader = true;
        break;
      }
      case INT_ESIGN: {
        draft.esignInitLoader = true;
        break;
      }
      case SAVE_INT_ESIGN: {
        draft.esignInitLoader = false;
        draft.esignResponse = action.payload.response;
        break;
      }
      case START_LOADER: {
        draft[action.payload.loaderName] = true;
        break;
      }
      case SAVE_EMAIL_ESIGN: {
        draft.loaderEsignDocument = false;
        draft.esignResponseEmail = action.payload.response;
        break;
      }    
      default:
        return state;
    }
    return draft;
  });

export default esignReducer;