const SEND_OTP = 'Mintifi/App/Mobile/SEND_OTP';
const STOP_LOADER = 'Mintifi/App/Mobile/STOP_LOADER';
const SAVE_OTP = 'Mintifi/App/Mobile/SAVE_OTP';
const VERIFY_OTP = 'Mintifi/App/Mobile/VERIFY_OTP';
const VERIFY_OTP_RESPONSE = 'Mintifi/App/Mobile/VERIFY_OTP_RESPONSE';
const CLEAR_OTP = 'Mintifi/App/Mobile/CLEAR_OTP';
const ANCHOR_SESSIONS_SEND = 'Mintifi/App/Mobile/ANCHOR_SESSIONS_SEND';
const DISTRIBUTOR_DETAILS = 'Mintifi/App/Mobile/DISTRIBUTOR_DETAILS';
const DISTRIBUTOR_LOADER = 'Mintifi/App/Mobile/DISTRIBUTOR_LOADER';

export {
  SEND_OTP,
  STOP_LOADER,
  SAVE_OTP,
  VERIFY_OTP,
  VERIFY_OTP_RESPONSE,
  CLEAR_OTP,
  ANCHOR_SESSIONS_SEND,
  DISTRIBUTOR_DETAILS,
  DISTRIBUTOR_LOADER
};
