import produce from 'immer';
import { SET_TOAST,LOAD_LOS,LOAD_BNPL,STOP_LOADER,STEP_COUNT,SAVE_BORROWER_DETAILS,SAVE_USER_DATA,CREATE_LEAD,SAVE_LEAD_DATA,SAVE_LEAD_ERROR_DATA,FETCH_BORROWER_DETAILS} from './app_constants';

export const initialState = {
  appLoader: false,
  stepCount:0,
  decrypt_data: null,
  leadLoader: true,
  leadDetails: null,
  leadErrorDetails: null
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_TOAST: {
        draft.myToasst = action.payload.toast;
        break;
      }
      case LOAD_LOS: {
        draft.appLoader = true;
        break;
      }
      case LOAD_BNPL: {
        draft.appLoader = true;
        break;
      }
      case STOP_LOADER: {
        draft[action.payload.loaderName] = false;
        break;
      }
      case STEP_COUNT: {
        draft.stepCount = action.payload.data;
        break;
      }
      case SAVE_BORROWER_DETAILS: {
        draft.decrypt_data = action.payload.decrypt_data;
        draft.personalLoader = false;
        break;
      }
      case SAVE_USER_DATA: {
        draft.userData = action.payload.data;
        draft.personalLoader = false;
        break;
      } 
      case CREATE_LEAD: {
        draft.leadLoader = true;
        break;
      }
      case SAVE_LEAD_DATA: {
        draft.leadLoader = false;
        draft.leadDetails = action.payload.data
        break;
      }
      case SAVE_LEAD_ERROR_DATA: {
        draft.leadLoader = false;
        draft.leadErrorDetails = action.payload.data
        break;
      }
      case FETCH_BORROWER_DETAILS: {
        draft.leadLoader = true;
        break;
      }
      default:
        return state;
    }
    return draft;
  });

export default appReducer;
