const AA_DATA = 'Mintifi/App/inclimit/AA_DATA';
const SAVE_AA_DATA = 'Mintifi/App/inclimit/SAVE_AA_DATA';
const STOP_LOADER = 'Mintifi/App/inclimit/STOP_LOADER';
const SAVE_NETBANKING_DATA = 'Mintifi/App/inclimit/SAVE_NETBANKING_DATA';
const NETBANKING_DATA = 'Mintifi/App/inclimit/NETBANKING_DATA';
const BS_DATA = 'Mintifi/App/inclimit/BS_DATA';
const SAVE_BS_DATA = 'Mintifi/App/inclimit/SAVE_BS_DATA';
const SAVE_ITR_DATA = 'Mintifi/App/inclimit/SAVE_ITR_DATA';
const ITR_DATA = 'Mintifi/App/inclimit/ITR_DATA';

const GET_GST = 'Mintifi/App/inclimit/GET_GST';
const SAVE_GST = 'Mintifi/App/inclimit/SAVE_GST';
const SUBMIT_GST = 'Mintifi/App/inclimit/SUBMIT_GST';
const SAVE_SUBMIT_GST = 'Mintifi/App/inclimit/SAVE_SUBMIT_GST';
const OTP_GST = 'Mintifi/App/inclimit/OTP_GST';
const SAVE_OTP_GST = 'Mintifi/App/inclimit/SAVE_OTP_GST';
const SUBMIT_ITR = 'Mintifi/App/inclimit/SUBMIT_ITR';
const SAVE_SUBMIT_ITR = 'Mintifi/App/inclimit/SAVE_SUBMIT_ITR';

const OPS_LOAN_CREATE = 'Mintifi/App/inclimit/OPS_LOAN_CREATE';
const OPS_TOKEN = 'Mintifi/App/inclimit/OPS_TOKEN';
const LOS_APP_RELOGIN = 'Mintifi/App/inclimit/LOS_APP_RELOGIN';
const LOS_APP_STATUS = 'Mintifi/App/inclimit/LOS_APP_STATUS';
const SAVE_LOS_APP_STATUS = 'Mintifi/App/inclimit/SAVE_LOS_APP_STATUS';

const INC_EMANDATE = 'Mintifi/App/inclimit/INC_EMANDATE';
const SAVE_INC_EMANDATE = 'Mintifi/App/inclimit/SAVE_INC_EMANDATE';
const INC_ESIGN = 'Mintifi/App/inclimit/INC_ESIGN';
const SAVE_INC_ESIGN = 'Mintifi/App/inclimit/SAVE_INC_ESIGN';
const UPLOAD_DOCUMENT = 'Mintifi/App/inclimit/Document/UPLOAD_DOCUMENT';
const SAVE_DOCUMENT = 'Mintifi/App/inclimit/Document/SAVE_DOCUMENT';
const CLEAR_LOS_DATA = 'Mintifi/App/inclimit/Document/CLEAR_LOS_DATA';



export {
  AA_DATA,
  SAVE_AA_DATA,
  STOP_LOADER,
  SAVE_NETBANKING_DATA,
  NETBANKING_DATA,
  BS_DATA,
  SAVE_BS_DATA,
  SAVE_ITR_DATA,
  ITR_DATA,
  GET_GST,
  SAVE_GST,
  SUBMIT_GST,
  SAVE_SUBMIT_GST,
  OTP_GST,
  SAVE_OTP_GST,
  SUBMIT_ITR,
  SAVE_SUBMIT_ITR,
  OPS_LOAN_CREATE,
  OPS_TOKEN,
  LOS_APP_RELOGIN,
  LOS_APP_STATUS,
  SAVE_LOS_APP_STATUS,
  INC_EMANDATE,
  SAVE_INC_EMANDATE,
  INC_ESIGN,
  SAVE_INC_ESIGN,
  SAVE_DOCUMENT,
  UPLOAD_DOCUMENT,
  CLEAR_LOS_DATA
};
