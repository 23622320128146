import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './configureStore';
import { retrieveParam } from './configurations/utils';
import TagManager from 'react-gtm-module'
import configuration from './configurations';
console.log("test222")
const defaultData = {};
console.log("test333")
if(configuration.GTM_ID) {

  const tagManagerArgs = {
    gtmId: configuration.GTM_ID
  }

  TagManager.initialize(tagManagerArgs)
}
console.log("test444")
if(window && window.location) {
console.log("test555")
  const { href } = window.location;
  const anchor_key = retrieveParam(href, "anchor_key") || undefined;
  const customer_code = retrieveParam(href, "customer_code") || undefined;
  const data = retrieveParam(href, "data") || undefined;
  const target = retrieveParam(href, "target") || undefined;
  const action = retrieveParam(href, "action") || undefined;
  console.log(window.location)
  if(anchor_key) {
    defaultData.apiKey = anchor_key;
  }

  if(customer_code) {
    defaultData.customerCode = customer_code;
  }

  if(target) {
    defaultData.target = target;
  }

  if(data) {
    defaultData.customerData = data;
  }

  if(action) {
    defaultData.action = action;
  }
  console.log("index.js",defaultData)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App defaultdata={defaultData} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
