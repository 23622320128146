import Axios from '../../configurations/axios';
import {
  GET_IFSC_URL,
  PENNY_DROP_URL,
  CREATE_BANK_URL,
  GET_NACH_BANK_URL,
  GET_INIT_EMANDATE_URL,
  GET_EMANDATE_PHY_NACH_URL,
  ACTIVATION_LIST_URL,
  ENCRYPT_CUSTOMER_DATA,
  CREATE_LEAD_URL
} from '../../configurations/urls';
import { store } from '../../configureStore';
import config from '../../configurations/index';

const getAuthToken = () => {
  return store.getState().PersonalReducer.authToken;
}

const getAnchorKey = () => {
  return store.getState().PersonalReducer.anchorKey;
}

const getAnchorPortalToken = () => {
  return config.ANCHOR_PORTAL_TOKEN;
}

const getIFSCService  = async (ifsc) => {
  
  const response = await Axios({
    url: GET_IFSC_URL+ifsc,
    method: 'GET',
  });
  return response.data;
};

const getIFSCAccService = async (ifscCode,accountNumber) => {
  
  const response = await Axios({
    url: PENNY_DROP_URL,
    method: 'POST',
    headers: {
      'api-key': getAnchorKey(),
      'Authorization': getAuthToken()
    },
    data: {
          "ifsc": ifscCode,
          "account_number": accountNumber
      }
  });
  return response.data;
};

const createBankAcccountService = async (request) => {
  
  const response = await Axios({
    url: CREATE_BANK_URL,
    method: 'POST',
    headers: {
      'api-key': getAnchorKey(),
      'Authorization': getAuthToken()
    },
    data: request
  });
  return response.data;
};

const getNachBankService = async () => {
  
  const response = await Axios({
    url: GET_NACH_BANK_URL,
    method: 'GET',
    headers: {
      'api-key': getAnchorKey(),
      'Authorization': getAuthToken()
    },
  });
  return response.data;
};

const initEmandateService = async (ui_url) => {
  
  const response = await Axios({
    url: GET_INIT_EMANDATE_URL,
    method: 'POST',
    headers: {
      'api-key': getAnchorKey(),
      'Authorization': getAuthToken()
    },
    data: {
      redirection_url: ui_url
    }
  });
  return response.data;
};

const phyNachService = async () => {
  
  const response = await Axios({
    url: GET_EMANDATE_PHY_NACH_URL,
    method: 'POST',
    headers: {
      'api-key': getAnchorKey(),
      'Authorization': getAuthToken()
    },
  });
  return response.data;
};

const setStepService = async (key) => {
  
  const response = await Axios({
    url: ACTIVATION_LIST_URL,
    method: 'POST',
    headers: {
      'api-key': getAnchorKey(),
      'Authorization': getAuthToken()
    },
    data:key
  });
  return response.data;
};

const encryptDataService = async (payload) => {
  
  const response = await Axios({
    url: ENCRYPT_CUSTOMER_DATA,
    method: 'POST',
    headers: {
      'api-key': getAnchorKey(),
      'Authorization': getAuthToken()
    },
    data:{
      payload:payload
    }
  });
  return response.data;
};
const createLeadService = async (key) => {
  
  const response = await Axios({
    url: CREATE_LEAD_URL,
    method: 'POST',
    headers: {
      'Accept': 'application/vnd.anchor_dashboard.com; version=2',
      'Content-Type': 'application/json',
      'Authorization': getAnchorPortalToken()
    },
    data:key
  });
  return response.data;
};
export { 
  getIFSCService,
  getIFSCAccService,
  createBankAcccountService,
  getNachBankService,
  initEmandateService,
  phyNachService,
  setStepService,
  createLeadService,
  encryptDataService
};
